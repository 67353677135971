import React, { useState, useEffect, useRef } from 'react'
import { Icon, Button, Segment, Message, Modal, Header, Checkbox, Select, Grid} from 'semantic-ui-react'

import { useAuth0 } from '../../../react-auth0-spa'
import { PageHeaderContainer, FlexThis, InfoTableContainer, LOADER_SEGMENT_HEIGHT, COMPANY_PRIMARY_COLOR, COMPANY_GREY_DARK } from '../../styled/styled'
import { useGlobalData } from '../../contexts/GlobalData'
import RewardsInfoTable from '../../components/InfoTables/RewardsInfoTable'
import numeral from 'numeral'
import TierCard from './TierCard'
import './Rewards.css'
//import DownForMaintenance from './DownForMaintenance'


const INITIAL_ALERT_OPTIONS = {
  alertTitle: "Insufficient Reward Points",
  alertBody: "You have insufficient reward points for the selected request, please select another. Apply for a loan to start earning reward points!"
}

function RewardsPage() {
  const { allData, getData, loansMap, setData } = useGlobalData()
  const { getTokenSilently } = useAuth0()
  const [negLoanCount, setNegLoanCount] = useState(0)
  const [rewardList, setRewardList] = useState(null)
  const [eligibleLoans, setEligibleLoans] = useState({})
  const [currentPoint, setCurrentPoint] = useState(0)
  const [requestSuccessful, setRequestSuccessful] = useState(false)
  const [selectedLoan, setSelectedLoan] = useState(null)
  const [selectedLoanError, setSelectedLoanError]= useState(false)
  const [selectedFee, setSelectedFee] = useState(null)
  const [selectedFeeAmount, setSelectedFeeAmount] = useState(0)
  const [selectedFeeError, setSelectedFeeError] = useState(false)
  const [selectedReward, setSelectedReward] = useState(null)
  const [rewardPoints, setRewardPoints] = useState(null)
  const [tier, setTier] = useState('')
  
  const [feeOptions, setFeeOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertOptions, setAlertOptions] = useState(INITIAL_ALERT_OPTIONS)
  const [showAgreement, setShowAgreement] = useState(false)
  const [readCheckbox, setReadCheckbox] = useState(false)
  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false)
  const [loanOptions, setLoanOptions] = useState()
  const [disablePayoffRedemption, setDisablePayoffRedemption] = useState(false)
  const [showTier, setShowTier] = useState(false)
  //const [processfeeMessage, setProcessfeeMessage] = useState(false)
  
  
  const messageEndings = {
	  'cf6d1558-7b02-eb11-a813-000d3a33f42d': 'payoff',
	  '074d79c6-b2ec-ea11-a817-000d3a33f42d': 'construction draw inspection',
	  '41af81d2-b2ec-ea11-a817-000d3a33f42d': 'processing fee',
	  '11f2503b-ada5-4a68-8f8b-af8a5213e74d': 'feasibility study',
	  '47507424-6a37-44a2-9e39-c7e37e2ececd': 'commitment fee',
	  '4dccaee2-b987-ee11-8179-000d3a5a6156': 'wiring fee',
	  'fe8c06fc-b987-ee11-8179-000d3a5a6156': 'other fee'
  }
  
  const tiers = {
	  'Tier1': 	{ 
					header: 'Tier 1',
					subHeader: '',
					bulletin: [<span>Minimum 5<sup>+</sup> similar completed projects on file over the past 36 months</span>],
				//	parallel: true,
				//	extra: {
				//		subHeader: 'First-Time Borrower with WCP',
				//		bulletin: ['6+ Completed Projects on Track Record']
				//	},
					textColor: COMPANY_PRIMARY_COLOR
				},
	  'Tier2': 	{ 
					header: 'Tier 2',
					subHeader: '',
					bulletin: [<span>Minimum 3<sup>+</sup> similar completed projects on file over the past 36 months</span>],
				//	parallel: true,
				//	extra: { 
				//		subHeader: 'First-Time Borrower with WCP',
				//		bulletin: ['3-5 Completed Projects on Track Record']
				//	},
					textColor: COMPANY_PRIMARY_COLOR
				},
	  'Tier3': 	{ 
					header: 'Tier 3',
					subHeader: '',					
					bulletin: [
						<span>Minimum 1<sup>+</sup> similar completed project on file over the past 36 months</span>
					],
				//	parallel: true,
					textColor: COMPANY_PRIMARY_COLOR
				},
	  'NoTier':	{ 
					header: 'No Tier',
					subHeader: '',
					bulletin: [
						'No track record on file over last 36 months'
					],
					textColor: 'grey'
				}
  }
  
  
  
  const fees = {
	  799520002: {feeTypeName: 'Free Construction Draw Inspection-300', feeAmount: 300, rewardId: '074d79c6-b2ec-ea11-a817-000d3a33f42d'},
	  799520003: {feeTypeName: 'Free Loan Payoff-250', feeAmount: 250, rewardId: 'cf6d1558-7b02-eb11-a813-000d3a33f42d'},
	  799520038: {feeTypeName: 'Free Feasibility Study-750', feeAmount: 750, rewardId: '11f2503b-ada5-4a68-8f8b-af8a5213e74d'},
	  799520037: {feeTypeName: 'Free Commitment Fee-', feeAmount: 0, rewardId:  '47507424-6a37-44a2-9e39-c7e37e2ececd'},
	  799520005: {feeTypeName: 'Free Processing Fee-', feeAmount: 0, rewardId: '41af81d2-b2ec-ea11-a817-000d3a33f42d'},
	  799520001: {feeTypeName: 'Free Wiring Fee-50', feeAmount: 50, rewardId: '4dccaee2-b987-ee11-8179-000d3a5a6156'},
	  799520031: {feeTypeName: 'Free Other Fee-', feeAmount: 0, rewardId: 'fe8c06fc-b987-ee11-8179-000d3a5a6156'},
  }
  
  const targetLoans = {}
  	  
  useEffect(() => {
	setSelectedFee(null)
	setSelectedLoan(null)
	let eligibleLoanIds = allData.fees.reduce((start, f) => start.concat(f.loanid), [])
	
 	if (eligibleLoanIds.length > 0) eligibleLoanIds.forEach(id => targetLoans[id] = {...loansMap[id]})
	
	setEligibleLoans(targetLoans)
	
    if(allData && allData.RewardsData && allData.Contact) {
      console.log('Reward Page all data: ')
	  console.dir(allData)
      // if not accepted agreement
      if (!allData.Contact.RewardAgreementAccepted) {
        setShowAgreement(true)
      }
	  setNegLoanCount(allData.NegLoanCount)
	  let rewards = [...allData.RewardsData.rewardsList]
	  
      setRewardList(rewards)
	  
      setRewardPoints(allData.Contact.RewardPoints)
	  setTier(allData.Contact.rewardTier ? tiers[allData.Contact.rewardTier].header : '')
	  
    }    
  }, [allData])

  useEffect(() => {
	  if (Object.keys(targetLoans).length > 0 && !loanOptions) {
      let options = []

      if (Object.keys(targetLoans).length > 0) {
        Object.keys(targetLoans).map((loanId, i) => {
          let loan = targetLoans[loanId]
          let address = loan.propertyAddress + ": $" + numeral(loan.WCPLoanAmount).format('0,0.00')
    
          options.push({
            key: i,
            text: address,
            value: loanId
          })
        })
      }
      setLoanOptions(options)
    }
  }, [loansMap])

  // accept reward https://borrowers-portal.azurewebsites.net/api/AcceptRewardAgreement?code=vWaoe8xRXHHFUaX1dporfYDXTkk4BxtKIUZZAXZ3EjaOWKbKTU1QYA==

  async function handleMessageDismiss(){
	setRequestSuccessful(false); 
	setRewardList(null);
	setSelectedReward(null)
	let reloadedData = await getData()
	setData(reloadedData.main)	
  }

  async function acceptRewardAgreement() {
    if (!readCheckbox) {
      setShowCheckboxAlert(true)
      return
    }
    setShowAgreement(false)
    let accessToken = await getTokenSilently()

    let reqBody = {
      accesstoken: accessToken,
      domainType: process.env.NODE_ENV,
      contactId: allData.Contact.contactId
    }

    //console.log('requesting... ', JSON.stringify(reqBody))
    setIsLoading(true)
    const SUBMIT_URL = reqBody.domainType === 'development' ? 'http://localhost:7071/api/AcceptRewardAgreement' : 'https://borrowers-portal.azurewebsites.net/api/AcceptRewardAgreement?code=vWaoe8xRXHHFUaX1dporfYDXTkk4BxtKIUZZAXZ3EjaOWKbKTU1QYA=='
    let req = await fetch(SUBMIT_URL, {
      method: 'post',
      body: JSON.stringify(reqBody)
    })

    if (req.status >= 200 && req.status < 300) {
      let data = await req.json()
	  setIsLoading(false)
	  if (data.error){
		setHasError(true);
	  }
	  //else{
		//setRequestSuccessful(true)
	  //}
    } else {
      setIsLoading(false)
      setHasError(true)
      console.log('Error: ', req)
    }
  }

  async function redeemRewardRequest() {
    if (!selectedLoan) {
      setAlertOptions({
        alertTitle: "No Loan Selected",
        alertBody: "Please specify the loan on which you wish to apply this reward."
      })
      setShowAlert(true)
      return
    }
    if (!rewardPoints || rewardPoints <= 0 || selectedFeeAmount > rewardPoints) {
      setAlertOptions(INITIAL_ALERT_OPTIONS)
      setShowAlert(true)
      return
    }
    let accessToken = await getTokenSilently()
	let selectedFeeId = allData.fees.filter(f => f.loanid == selectedLoan && f.feeType == selectedFee)[0].feeid
    let reqBody = {
      accesstoken: accessToken,
      domainType: process.env.NODE_ENV,
      borrowerRewardId: fees[selectedFee].rewardId,
      contactId: allData.Contact.contactId,
      loanId: selectedLoan,
	  feeTypeId: selectedFee,
	  feeAmount: selectedFeeAmount,
	  feeId: selectedFeeId
    }

    //console.log('requesting... ', JSON.stringify(reqBody))
    setIsLoading(true)

    const SUBMIT_URL = reqBody.domainType === 'development' ? 'http://localhost:7071/api/CreateBorrowerRewardRedemption' : 'https://borrowers-portal.azurewebsites.net/api/CreateBorrowerRewardRedemption?code=12imi9HTmNmDuA/qR6z1RfG3WOOrk3w6cSaekXzOzAsfaasC53Ip9g=='
    let req = await fetch(SUBMIT_URL, {
      method: 'post',
      body: JSON.stringify(reqBody)
    })

    if (req.status >= 200 && req.status < 300) {
      let data = await req.json()
      setIsLoading(false)
      setRequestSuccessful(true)
	  setRewardPoints(rewardPoints - selectedFeeAmount)
     // let reloadedData = await getData()
	 // setData(reloadedData.main)	  
    } else {
      setIsLoading(false)
      setHasError(true)
      console.log('Error: ', req)
    }
  }

  const handleLoanSelect = (e, { name, value }) => {
    setSelectedLoan(value);
	setSelectedLoanError(!value)
	containsPayoffs(value) ? setDisablePayoffRedemption(false) : setDisablePayoffRedemption(true)
	
	let currentFees = allData.fees.filter(l => l.loanid == value)
	
	let distinctFees = currentFees.reduce((start, f) => { 
				return start.length == 0 ?  start.concat(f) :
					start.filter(s=>s.feeType==f.feeType && s.loanid == f.loanid).length > 0 ? start : start.concat(f)
			}, [])
	
	let resultFees = distinctFees.map( (f, i) => {
				if([799520037, 799520005, 799520031].includes(f.feeType)) fees[f.feeType].feeTypeName = fees[f.feeType].feeTypeName.split('-')[0] + '-' + f.feeAmount
				return {key: i, value: f.feeType, text: f.feeName + ': ' + fees[f.feeType].feeTypeName}
			})

	currentFees.length > 0 && setFeeOptions(resultFees)//dynamically set fee types
  }


  const handleFeeSelect = (e, {name, value}) => {
	  setSelectedFeeError(!value)
	  setSelectedFee(value)
	  setSelectedFeeAmount([799520037, 799520005, 799520031].includes(value) ? allData.fees.filter(f => f.loanid == selectedLoan && f.feeType == value)[0].feeAmount : fees[value].feeAmount)
  }

  const containsPayoffs = loanid => {
	 if(allData && allData.Loans && allData.Loans.length > 0){
		let targetLoan = allData.Loans.filter(l => l.id == loanid)[0] //first find the loan with the same id
		return targetLoan && targetLoan.Payoffs && targetLoan.Payoffs.length > 0
	 }else
		 return false
  }
  
  function alertModal() {
    return (
      <div style={{ padding: 25, textAlign: 'center' }}>
        <Message 
        negative
        header={alertOptions.alertTitle}
        content={alertOptions.alertBody}
        onDismiss={() => setShowAlert(false)}
        />
      </div>
    )
  }

  function agreementModal() {
    return (
      <Modal
        open={showAgreement}
        onClose={() => setShowAgreement(false)}
        onOpen={() => setShowAgreement(true)}
        trigger={null}
      >
        <Modal.Header>Rewards Program Agreement</Modal.Header>
        <Modal.Content scrolling>  
          <Modal.Description>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowAgreement(false)} primary>
            Proceed <Icon name='chevron right' />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return(
    <React.Fragment>
      {showAgreement ?
      <InfoTableContainer style={{ position: 'relative', width: '60%', left: 75, right: 75 }}>
        <p><b><u>WCP Rewards Program Terms and Conditions </u></b></p>
        <p><b>Please read the WCP Rewards Program Terms carefully for important information about your rights and obligations. By participating in the WCP Rewards Program, you agree to these terms and conditions. The purpose of the WCP Rewards Program is to reward you for continuing to choose WCP as your hard money lender. These WCP Rewards Program Terms may be supplemented with additional terms, conditions, and disclosures, including but not limited to applicable terms related to any promotional offers provided to borrowers for use with the WCP Rewards Program.</b></p>
        <p>Your Points balance will be available by logging into your borrower portal. Please note that it often takes 1-2 weeks or more for Points earned to post to your Points balance. At any time, we may verify and adjust your Points balance based on our records. Points will remain available in your Points balance until they are redeemed for a Reward as described or forfeited. When you earn enough Points, you will be eligible to apply the points for Rewards, as long as your loan(s) with WCP are in Good Standing. Points are redeemable for a specified dollar amount off qualifying fees and expenses. These WCP Rewards Program Terms are separate from your loan approval conditions and other terms associated with your loan(s). By using your Points, you agree to be bound by these WCP Rewards Program Terms. WCP is solely responsible for the WCP Rewards Program operation and may withdraw the WCP Rewards Program or change the Terms of the WCP Rewards Program at any time.</p>
        <p>You must be in good standing with no delinquent loan payments at the time of request in order to redeem reward points. WCP may temporarily prohibit you from earning Points, using Points, or using any features of the WCP Rewards Program if: any of your WCP loan(s) become delinquent; WCP suspects that you have engaged in fraudulent activity related to your loan(s) or the Rewards Program; or WCP suspects that you misused the WCP Rewards Program in any way. Points will be forfeited if any of the following occur: your loan(s) goes into foreclosure; you terminate your participation in the WCP Rewards Program; you engage in any fraudulent activity or misuse related to your WCP loan(s) or the WCP Rewards Program.</p>
        <p>Requests to use Points towards a new loan must be made in writing at the time of loan origination. All loan approval conditions still apply. Points cannot be combined with other discounts or promotions, unless those discounts or promotions state otherwise. All other fees and charges still apply. Points may not be used retroactively and are not redeemable for cash under any circumstances. Points are nontransferable. <b>Subject to any requirements or limitations of applicable law, WCP may at any time for any reason change or terminate the WCP Rewards Program and these WCP Rewards Program Terms without notice.</b> WCP reserves the right to terminate you from the WCP Rewards Program. If the WCP Rewards Program terminates, WCP terminates you from participating in the WCP Rewards Program, or you voluntarily cancel enrollment in the WCP Rewards Program, all Points will be forfeited.</p>
        <p>Points may not be sold, transferred or altered in any way by you. Points may not be used as payment for any outstanding obligations to WCP or its affiliates. If Points are applied towards a loan that does not close, at no fault of WCP, then those Points are not refundable and may not be applied to any future loan(s). Exclusions apply and may change from time to time.</p>
        <p>If Points you believe were earned have not timely posted to your Points balance, you may dispute your Points balance by contacting your loan officer. WCP will use reasonable efforts to investigate your Points dispute if you notify WCP within 90 days of the posting date. If you do not notify WCP within that period, you waive your right to make a Points dispute with respect to that dispute. WCP may require you to provide written confirmation of the dispute and the applicable financing amount and may decline to investigate further if you do not provide the requested confirmation or a valid receipt. Upon completion of the investigation, WCP will have no further responsibilities if you later reassert the same Points dispute.</p>
        <p>Unless otherwise required by law, WCP, nor any of our or its affiliates, officers, directors, employees, service providers, or agents will be liable to you, or anyone making a claim on your behalf, in connection with the WCP Rewards Program or these WCP Rewards Program Terms, including but not limited to, use of the WCP Rewards Program and any Points redeemed through the WCP Rewards Program. <b>Important note: Notwithstanding the foregoing, any liability that WCP may have to you in connection with the WCP Rewards Program shall be limited to the amount of any Points you have earned in accordance with these WCP Rewards Program Terms.</b> WCP, and all of its subsidiaries and affiliates make no warranty, express or implied with respect to the WCP Rewards Program or any products or services related to such WCP Rewards Program. You are responsible for any tax liability that may result from participation in the WCP Rewards Program. Consult your tax advisor concerning such tax consequences.</p>
        <p>You may cancel your participation in the WCP Rewards Program at any time by contacting your loan officer. If you cancel your participation in the WCP Rewards Program, you will no longer earn Points and you will forfeit your unused Points. Your cancellation will not affect any previously redeemed Points.</p>
        <p>The WCP Rewards Program and these WCP Rewards Program Terms are governed by federal law and, to the extent state law applies, the laws of the Commonwealth of Virginia, you may not assign your rights or obligations under these WCP Rewards Program Terms to any other person or entity. <b>Important note: You and WCP agree that each of us may bring claims arising from or relating to these WCP Rewards Program Terms only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding.</b> "Claim," as used in these WCP Rewards Program Terms means any claim, dispute or controversy that in any way arises from or relates to these WCP Rewards Program Terms.</p>
        <p>These WCP Rewards Program Terms supersede any previous terms and conditions governing the WCP Rewards Program. The WCP Rewards Program is not available where and to the extent prohibited by law. If any part of these WCP Rewards Program Terms conflict with applicable law, that provision will be deemed severed from these WCP Rewards Program Terms and the remainder of the WCP Rewards Program Terms will remain in effect. WCP will not lose our rights under these WCP Rewards Program Terms because WCP chooses to delay or not enforce them. If you have any questions about the WCP Rewards Program, please contact your loan officer.</p>
        <p><Checkbox value={readCheckbox} onChange={() => setReadCheckbox(!readCheckbox)} /> I have read the WCP Rewards Program Terms and Conditions</p>
        {showCheckboxAlert && <p style={{ color: 'red' }}>Please check the box above if you have read the terms and conditions.</p>}
        <p style={{ textAlign: 'center' }}>
          <Button primary size='large' onClick={() => acceptRewardAgreement()}>I Agree</Button>
        </p>
      </InfoTableContainer>
      :
      <InfoTableContainer>
        {requestSuccessful ?
        <div style={{ padding: 25, textAlign: 'center' }}>
			<Message visible positive icon>
				<Icon name="check circle outline" />
				<Message.Content visible>
				  <Message.Header visible>Your submission was successful!</Message.Header>
					<div>This will be used for your next requested {messageEndings[fees[selectedFee].rewardId]}.</div>				
					<div>Clicking okay will reload app data.</div>
				</Message.Content>
			</Message>
			<Button primary size='large' onClick={handleMessageDismiss}>Okay</Button>
        </div>
        :
        hasError ?
        <div style={{ padding: 25, textAlign: 'center' }}>
          <Message negative icon>
            <Icon name="x" />
            <Message.Content>
              <Message.Header>Error Submitting Your Request</Message.Header>
              Please refresh the page and try again or contact support if there are still errors.
            </Message.Content>
          </Message>
          <Button primary size='large' onClick={() => setHasError(false)}>Okay</Button>
        </div>
        :
        isLoading ?
        <div style={{ padding: 25, textAlign: 'center' }}>
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Please Wait</Message.Header>
              Submitting your request...
            </Message.Content>
          </Message>
        </div>
        :
        <React.Fragment>
          {showAlert && alertModal()}
          <PageHeaderContainer style={{ fontSize: 36, padding: 25 }}>Rewards</PageHeaderContainer>
          <hr style={{ border: '1px solid #a8a8a8' }} />
          <PageHeaderContainer style={{ fontSize: 30, color: COMPANY_GREY_DARK, paddingTop: 25 }}>Your Available Reward Points:</PageHeaderContainer>
          <PageHeaderContainer style={{ fontSize: 40, paddingBottom: 25 }}>{`${rewardPoints ? rewardPoints : 0}`}</PageHeaderContainer>
		  <PageHeaderContainer style={{ fontSize: 30, color: COMPANY_GREY_DARK, paddingTop: 25 }}>Your Rewards Tier:</PageHeaderContainer>
		  <PageHeaderContainer style={{ fontSize: 40, paddingBottom: 25 }}>{tier}</PageHeaderContainer>
		  <PageHeaderContainer style={{ paddingBottom: 25 }}>
			<Icon name="exclamation circle" style={{fontSize: 22}}/>
			<span style={{ fontSize: 18, color: COMPANY_GREY_DARK}} onClick={() => setShowTier(true)}>Click To Open Commitment Fee Rewards Tier Chart</span>
		  </PageHeaderContainer>
          <hr style={{ border: '1px solid #a8a8a8' }} />
          <PageHeaderContainer style={{ fontSize: 30, color: COMPANY_GREY_DARK, padding: 25 }}>Step 1) Choose Your Loan:<span style={{color:'red'}}> *</span></PageHeaderContainer>
          <FlexThis>
            {Object.keys(eligibleLoans).length > 0 ? 
            <Select
              style={selectedLoanError ? { width: '60%', border: '2px solid red' } : { width: '60%' }}
              required
              label='Select Loan'
              name='loanId'
              options={loanOptions}
              onChange={handleLoanSelect}
              placeholder={'Please select a loan'}
            /> : <p> No loans available with eligible fees </p>}
          </FlexThis>
          <hr style={{ border: '1px solid #a8a8a8' }} />
          <PageHeaderContainer style={{ fontSize: 30, color: COMPANY_GREY_DARK, padding: 25 }}>Step 2) Choose How To Redeem Your Points:<span style={{color:'red'}}> *</span></PageHeaderContainer>
			{Object.keys(eligibleLoans).length > 0 ? 
				!negLoanCount ? <FlexThis>
				{<Select
				  style={selectedFeeError ? { width: '50%', border: '2px solid red' } :{ width: '50%' }}
				  required
				  label='Select fee type'
				  name='feeType'
				  options={feeOptions}
				  onChange={handleFeeSelect}
				  placeholder={'Please select a fee type'}
				/>}
				<button className="ui button primary" onClick={redeemRewardRequest} disabled={!(selectedLoan && selectedFee)}>Submit</button>
			</FlexThis>
			: <div>
				<p style={{ textAlign: 'center' }}>Reward points cannot be redeemed due to existence of negative loans.</p>
				<p style={{ textAlign: 'center' }}>Please reach out to our servicing team at (703) 940-5190 or via email at servicing@wcp.team</p>
			</div> : ''}
          {selectedReward &&
          <div style={{ textAlign: 'center' }}>
            <hr />
            <Button style={{ backgroundColor: COMPANY_PRIMARY_COLOR, color: 'white', width: 200 }} onClick={() => {redeemRewardRequest();setSelectedLoan(false)}} size="large">Submit</Button>
          </div>}
          <hr style={{ border: '1px solid #a8a8a8' }} />
          {rewardList&&allData && allData.RewardsData && allData.RewardsData.borrowerRewardRemptions.length > 0 &&
          <RewardsInfoTable rewardsData={allData.RewardsData} />}
          {!rewardList && <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '100%', margin: 'auto', border: 'none' }} />}
        </React.Fragment>}
		<Modal
			size='large'
			open={showTier}
			onClose={() => setShowTier(false)}
			style={{height: '65%', top: '10%', left: '15%', alignItems: 'center'}}
		 >
			<Modal.Content style={{paddingTop: '2%', }}>
				<Grid style={{position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom:'5px', padding: '0px'}}>
					<span onClick={()=>setShowTier(false)} className='cross-mark'>X</span></Grid>
				<Grid columns={4} style={{margin: '0'}}>
					{Object.keys(tiers).reverse().map( k => ({...tiers[k]}))
						.map( (v,i) => <Grid.Column key={'tier' + i}><TierCard tier={v} /></Grid.Column>)}
				</Grid>
			</Modal.Content>      
		</Modal>
      </InfoTableContainer>}
	 
    </React.Fragment>
  )
} 

//const RewardsPage = () => <DownForMaintenance />
export default RewardsPage