import React, { useState, useEffect } from 'react'
import { Segment, Form, Input, Button } from 'semantic-ui-react'

import { LOADER_SEGMENT_HEIGHT, PageHeaderContainer, InfoTableContainer, HEADER_3_SIZE } from '../../styled/styled'
import { useGlobalData } from '../../contexts/GlobalData'
import { useAuth0 } from '../../../react-auth0-spa'

function ImpersonationForm() {
  const INITIAL_FORM_VALUES = {
    impersonationEmail: ''
  }
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [error, setError] = useState(false)
  const { borrowerInfo, handleImpersonationEmail, loading } = useGlobalData()

  const handleChange = (e, { name, value }) => setFormValues({ ...formValues, [name]: value })

  function handleSubmit() {
    console.log('USER ROLES: ', borrowerInfo.roles)
    if (formValues.impersonationEmail.length > 0 && (/\S+@\S+\.\S+/).test(formValues.impersonationEmail)) {
      handleImpersonationEmail(formValues.impersonationEmail)
    } else {
      setError(true)
    }
  }

  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') handleSubmit()
  })

  return (
    <Form>
      <Form.Field 
      control={Input}
      error={error}
      name='impersonationEmail'
      label='Impersonation Email'
      onChange={handleChange}
      value={formValues.impersonationEmail} />

      <Button onClick={() => handleSubmit()}>Submit</Button>
    </Form>
  )
}

export default ImpersonationForm