import styled from 'styled-components'

// blues
export const COMPANY_PRIMARY_COLOR = '#1c79a5'
export const COMPANY_SECONDARY_COLOR = '#239fff'
export const COMPANY_ACTIVE_BLUE = '#2185d0'
export const COMPANY_EXTRA_DARK_BLUE = '#104d67'

// greys
export const COMPANY_GREY_DARK = '#7b7b7b'
export const COMPANY_GREY_LIGHT = '#a8a8a8'
export const COMPANY_GREY_DARKER_BG = '#838383'
export const COMPANY_GREY_LIGHTER = '#E8E8E8'
export const SHADOW_GREY = '#999'

// other
export const POSITIVE_PASTEL_GREEN = '#b3f9b5'
export const UPCOMING_PASTEL_YELLOW = '#fff9b7'
export const LATE_PASTEL_RED = '#ffb2ad'

const ATTN_FONT_FAMILY = 'monospace'
const INFO_FONT_FAMILY = 'Didact Gothic, sans-serif'

const NAVBAR_HEIGHT = 30
const NAVBAR_ITEM_WIDTH = 50

export const HEADER_1_SIZE = 32
export const HEADER_2_SIZE = 24
export const HEADER_3_SIZE = 16
export const HEADER_4_SIZE = 14

const TABLE_BORDER_RADIUS = 20
const TABLE_ROW_BORDER_RADIUS = 10


// eaiser to maintain height from one central location
export const LOADER_SEGMENT_HEIGHT = 300

// wraps whole site at AppEntry.js
export const SiteWrapper = styled.div`
  min-height: 100%;
  height: auto;
`

// wraps each page (wraps the router)
export const PageWrapper = styled.div`
  padding: 10px;
  width: inherit;
`

// table used to display loans, loan payments, construction draws, payoffs etc.
export const InfoTable = styled.table`
  border: none;
  margin: auto;
  min-width: 100%;
  background-color: white;
  border-collapse: separate;
  border-radius: 1em;
  border-spacing: 0 10px;
  overflow-x: auto;

  #loans-info {
    font-size: ${HEADER_3_SIZE}
  }

  th, td {
    border: none;
    padding: 5px;
    font-size: ${HEADER_4_SIZE}px;
    font-family: ${INFO_FONT_FAMILY};
  }
  
  tr {
    border: 1px solid black;

    td:first-child {
      -moz-border-radius-topleft: ${TABLE_ROW_BORDER_RADIUS}px;
      -moz-border-radius-bottomleft: ${TABLE_ROW_BORDER_RADIUS}px;
      -webkit-border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
      -webkit-border-bottom-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;

      border-left: 1px solid black;
    }

    td:last-child {
      -moz-border-radius-topright: ${TABLE_ROW_BORDER_RADIUS}px;
      -moz-border-radius-bottomright: ${TABLE_ROW_BORDER_RADIUS}px;
      -webkit-border-top-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
      -webkit-border-bottom-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;

      border-right: 1px solid black;
    }

    td {
      border-bottom: 1px solid black;
      border-top: 1px solid black;
    }
  }

  th {
    width: fit-content;
    background-color: ${COMPANY_GREY_LIGHTER};
  }

  thead {
    background-color: transparent;
    color: black;
    border-radius: ${TABLE_BORDER_RADIUS}px;
  }

  @media (max-width: 1000px) {
    th {
      display: block;
    }

    td {
      display: block;
    }

    tr {
      border: none;
  
      td:first-child {
        -moz-border-radius-topleft: ${TABLE_ROW_BORDER_RADIUS}px;
        -moz-border-radius-bottomleft: none;
        -webkit-border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
        -webkit-border-bottom-left-radius: none;
  
        border-left: none;
      }
  
      td:last-child {
        -moz-border-radius-topright: none;
        -moz-border-radius-bottomright: ${TABLE_ROW_BORDER_RADIUS}px;
        -webkit-border-top-right-radius: none;
        -webkit-border-bottom-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  
        border-right: none;
      }
  
      td {
        border-bottom: none;
        border-top: none;
      }
    }
  }
`

export const HoverTableRow = styled.tr`
  border: 1px solid black;

  td:first-child {
    -moz-border-radius-topleft: ${TABLE_ROW_BORDER_RADIUS}px;
    -moz-border-radius-bottomleft: ${TABLE_ROW_BORDER_RADIUS}px;
    -webkit-border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
    -webkit-border-bottom-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;

    border-left: 1px solid black;
  }

  td:last-child {
    -moz-border-radius-topright: ${TABLE_ROW_BORDER_RADIUS}px;
    -moz-border-radius-bottomright: ${TABLE_ROW_BORDER_RADIUS}px;
    -webkit-border-top-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
    -webkit-border-bottom-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;

    border-right: 1px solid black;
    box-shadow: 2px 2px ${COMPANY_GREY_LIGHTER};
  }

  td {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    box-shadow: 0 2px ${COMPANY_GREY_LIGHTER};
  }

  :hover {
    background-color: ${COMPANY_GREY_LIGHT};
    color: white;
    transform: translateY(-2px);
    cursor: pointer
  }

  :active {
    td:last-child {
      box-shadow: none;
    }
  
    td {
      box-shadow: none;
    }
    
    transform: translateY(1px);
  }
  
  @media (max-width: 1000px) {
    background-color: ${COMPANY_GREY_LIGHT};
    font-weight: bold;
    color: ${COMPANY_ACTIVE_BLUE};

    td {
      border-bottom: none;
      border-top: none;
      box-shadow: none;
    }

    td:first-child {
      -moz-border-radius-topleft: ${TABLE_ROW_BORDER_RADIUS}px;
      -moz-border-radius-bottomleft: none;
      -webkit-border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
      -webkit-border-bottom-left-radius: none;

      border-left: none;
    }

    td:last-child {
      -moz-border-radius-topright: none;
      -moz-border-radius-bottomleft: none;
      -webkit-border-top-right-radius: none !important;
      -webkit-border-bottom-right-radius: none !important;

      border-right: none;
      box-shadow: none;
    }
  }
`

export const CompanyLogoImage = styled.img`
  width: 100%;
  height: 100%;
`

export const NavBarWrapper = styled.div`
  padding-bottom: 60px;
  div > div > a {
    font-family: ${INFO_FONT_FAMILY};
    font-size: ${HEADER_3_SIZE}px;
    color: transparent !important;
  }
`

// centers and modifies the text contained within it
export const PageHeaderContainer = styled.div`
  width: 100%;
  font-size: ${HEADER_1_SIZE}px;
  font-family: ${INFO_FONT_FAMILY};
  font-weight: bold;
  padding: 5px;
  display: flex;
  justify-content: center;
  color: ${COMPANY_PRIMARY_COLOR};
  margin-bottom: 10px;
`

// wrap icons to make them interactive looking
export const HoverWrapper = styled.div`
  text-align: center;
  font-size: 1em;
  margin: 2px;

  :hover {
    color: ${COMPANY_SECONDARY_COLOR};
  }
`
export const RefreshIconWrapper = styled.div`
  text-align: center;

  i {
    font-size: 0.8em !important;
  }

  :hover {
    color: ${COMPANY_SECONDARY_COLOR};
    font-weight: bold;
  }
`

export const FlexThis = styled.div`
  display: flex;
  justify-content: center;
`

export const LoanPaymentTab = styled.details`
  border-collapse: collapse;
  cursor: pointer;
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
`

export const LoanPaymentTabTitle = styled.summary`
  padding: 12px;
  border-radius: ${TABLE_BORDER_RADIUS}px;
  font-family: ${INFO_FONT_FAMILY};
  border-bottom: solid 1px;
  background-color: ${COMPANY_GREY_LIGHTER};
  display: flex;
  font-size: ${HEADER_3_SIZE}px;
  font-weight: bold;
  
  margin-top: 0px;
  box-shadow: 1px 2px ${SHADOW_GREY};
  height: 55px;

  :hover {
    background-color: ${COMPANY_GREY_LIGHT};
    color: white;
    transform: translateY(-2px);
  }
`

// main wrapper of expandable tabs
export const InfoTableContainer = styled.div`
  max-width: 60%;
  margin: auto;
  margin-bottom: 0px;
  border: 1px solid black;
  padding: 20px;
  background-color: white;
  border-radius: ${TABLE_BORDER_RADIUS}px;
  margin-bottom: 10px !important;
  box-shadow: 3px 6px ${SHADOW_GREY};

  @media (max-width: 1000px) {
    max-width: 95%;
  }
`

export const InnerTableContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid black;
  border-radius: ${TABLE_BORDER_RADIUS}px;
  margin-bottom: 10px;
`

export const InfoCard = styled.div`
  text-align: right;
  border: 1px solid black;
  padding: 20px;
  width: auto;
  display: flex;
  border-radius: ${TABLE_BORDER_RADIUS}px;
  margin-bottom: 10px;
  background-color: white;
`

export const InfoCardLeftSide = styled.div`
  text-align: left;
  font-size: ${HEADER_3_SIZE}px;
  font-family: ${INFO_FONT_FAMILY};
`

export const InfoCardRightSide = styled.div` 
  text-align: right; 
  font-size: ${HEADER_3_SIZE}px;
  font-family: ${INFO_FONT_FAMILY};
  margin-left: auto;
`

export const LoginButton = styled.div`
  width: 200px;
  background-color: ${COMPANY_ACTIVE_BLUE};
  color: white;
  font-family: ${INFO_FONT_FAMILY};
  font-size: ${HEADER_2_SIZE}px;
  margin: auto;
  text-align: center;
  padding: 20px;
  box-shadow: 0 3px ${SHADOW_GREY};
  border-radius: 15px;
  border: 3px solid ${COMPANY_EXTRA_DARK_BLUE};

  :hover {
    background-color: ${COMPANY_EXTRA_DARK_BLUE};
    transform: translateY(-2px);
    cursor: pointer;
  }

  :active {
    box-shadow: 0 1px ${SHADOW_GREY};
    transform: translateY(2px);
  }
`

export const TabbedTableContainer = styled.div`
  width: 60%;
  margin: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: 3px 6px ${SHADOW_GREY};
  border: 1px solid black;

  @media (max-width: 1000px) {
    width: 95%;
  }
`