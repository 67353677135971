import React, {useState} from 'react'
import { Segment, Grid} from 'semantic-ui-react'

import {
  InfoTableContainer as TabsContainer, 
  PageHeaderContainer
} from '../../../styled/styled'
import { useGlobalData } from '../../../contexts/GlobalData';
import LoanDraws from './LoanDraws'


/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function ConstructionDrawsInfoTable() {
  const { loans, allData, setAllData } = useGlobalData();
  // construction draw disbursed amount
  // construction fund amount
  // determine percentage disbursed

  return (
    <TabsContainer>
      <PageHeaderContainer style={{ marginBottom: 5 }}>Construction Draws</PageHeaderContainer>
      {loans ?
        loans.map((loan, i) => {
          // hide loans that are not serviced by us
          if(loan.ConstructionDraws.length > 0) {
            return <LoanDraws loan={loan} key={i} data={allData} setFullData={setAllData}/>
          }
        })
        :
        <Segment loading style={{ height: 300, width: '100%' }} />
      }
    </TabsContainer>
  )
}

export default ConstructionDrawsInfoTable