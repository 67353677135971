import React, { useEffect, useState, useRef } from 'react'
import { Icon, Segment, Header, Button } from 'semantic-ui-react'

import { useGlobalData } from '../../contexts/GlobalData'
import { LOADER_SEGMENT_HEIGHT } from '../../styled/styled'
import PayoffRequestForm from '../../components/Forms/PayoffRequestForm'

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function PayoffsRequestPage() {
  const { loansInfoMap, borrowerInfo, loansMap, loading } = useGlobalData()
  const [payoffRequestFormOptions, setPayoffRequestFormOptions] = useState()

  useEffect(() => {
    let options = []

    if (loansMap) {
      Object.keys(loansMap).map((loanId, i) => {
        let loan = loansMap[loanId]
        let address = loan.propertyAddress
  
        options.push({
          key: i,
          text: address,
          value: loanId
        })
      })
    }

    console.log('Payoff Optons: ', options)

    setPayoffRequestFormOptions(options)
  }, [loansMap])

  if (!loansInfoMap || loading) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  return(
    <React.Fragment>
      <PayoffRequestForm email={borrowerInfo.email} options={payoffRequestFormOptions} />
    </React.Fragment>
  )
}

export default PayoffsRequestPage