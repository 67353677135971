import React, { useState, useEffect } from 'react'
import { Header, Icon, Segment, Button } from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';
import PayoffRequestForm from '../Forms/PayoffRequestForm'

import { 
  InfoTable, 
  HoverWrapper, 
  FlexThis, 
  InfoTableContainer, 
  PageHeaderContainer,
  COMPANY_PRIMARY_COLOR,
  COMPANY_GREY_DARK,
  LOADER_SEGMENT_HEIGHT,
  HEADER_3_SIZE,
  HoverTableRow,
  UPCOMING_PASTEL_YELLOW,
  POSITIVE_PASTEL_GREEN,
  COMPANY_EXTRA_DARK_BLUE,
  TabbedTableContainer,
  InnerTableContainer,
  COMPANY_ACTIVE_BLUE
} from '../../styled/styled'
import { GetLoanStatusText, sortDatesDesc, GetRewardRedemptionStatus } from '../../lib/helpers'
import { withRouter } from 'react-router-dom';
import { useGlobalData } from '../../contexts/GlobalData';

/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function RewardsInfoTable({ rewardsData }) {
  const { loansInfoMap, borrowerInfo, loansMap, loans } = useGlobalData()
  const [rewardListLookup, setRewardListLookup] = useState(null)
  
  useEffect(() => {
    if (!rewardListLookup) {
      let lookupObj = {}
      rewardsData.rewardsList.map((r, i) => lookupObj[`${r.Id}`] = r)
      setRewardListLookup(lookupObj)
    }
  }, [rewardListLookup])

  /**
   * submitted: 799,520,000
   * under review: 799,520,001
   * approved: 799,520,002
   * rejected: 799,520,003
  */


  return (
    <React.Fragment>
      <PageHeaderContainer style={{ color: COMPANY_GREY_DARK, fontSize: 30, padding: 25 }}>Your Previous Redemption Requests:</PageHeaderContainer>
      {rewardsData.borrowerRewardRemptions.length > 0 ?
      <InfoTable>
        <thead>
          <tr>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Name</p></th>
			<th><p style={{ fontSize: HEADER_3_SIZE }}>Loan</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Points</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Requested On</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Status</p></th>
          </tr>
        </thead>
        <tbody>
        {rewardListLookup && rewardsData.borrowerRewardRemptions.sort((r1, r2) => sortDatesDesc(r1.RequestedOn, r2.RequestedOn)).map((redemption, i) => {
          const reward = rewardListLookup[redemption.reward]
          if (reward) {
            return (
              <tr key={i}>
                <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{reward.RewardName}</p></td>
				<td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{redemption.rewardLoanName}</p></td>
                <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{redemption.rewardPointsRedeemed}</p></td>
                <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{moment.utc(redemption.RequestedOn).format('MM/DD/YYYY')}</p></td>
                <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{redemption.status}</p></td>
              </tr>
            )
          }
        })}
        </tbody>
      </InfoTable>
      :
      null}
    </React.Fragment>
  )
}

export default withRouter(RewardsInfoTable)