import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Icon, Menu, Image, Message } from "semantic-ui-react";

import { useAuth0 } from '../../../react-auth0-spa';
import { 
  COMPANY_EXTRA_DARK_BLUE, 
  NavBarWrapper, 
  PageHeaderContainer, 
  HEADER_4_SIZE, 
  COMPANY_ACTIVE_BLUE 
} from '../../styled/styled';
import { useGlobalUI } from "../../contexts/GlobalUI";
import { useGlobalData } from "../../contexts/GlobalData";


function NavBar() {
  const { isAuthenticated, loginWithRedirect, logout, loading, user } = useAuth0();
  const { toggleVisibility, visible } = useGlobalUI();
  const { impersonationEmail } = useGlobalData();

  return (
    <NavBarWrapper>
      {isAuthenticated ?
      <React.Fragment>
      {/*  <Helmet>
          <script>{`
            window.intercomSettings = {
              app_id: 'jogpe1qi',    
              horizontal_padding: 30, 
              vertical_padding: 20,
            }
          `}</script>
          <script>{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + 'jogpe1qi';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}</script>
	  </Helmet> */}
        <Menu fixed='top' pointing secondary>
          <Menu.Menu position='left'>
            <Menu.Item 
            style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}
            onClick={() => {
              toggleVisibility()
              console.log('from navbar: ', visible)
            }}>
              <Icon id='sidebar-opener' name='bars' />
            </Menu.Item>
          </Menu.Menu>
          {user && impersonationEmail &&
          <Menu.Menu position='right'>
            <Message style={{ textAlign: 'center', height: 40, marginTop: 'auto', marginBottom: 'auto' }} warning>
              <Message.Header><span style={{ color: '#1c79a5' }}>{user.email}</span> viewing as: <span style={{ color: 'green' }}>{impersonationEmail}</span></Message.Header>
            </Message>
          </Menu.Menu>}
          <Menu.Menu position='right'>
            <Menu.Item
              id='logout-button'
              style={{ backgroundColor: COMPANY_ACTIVE_BLUE }}
              name='logout'
              onClick={() => logout()}
            />
          </Menu.Menu>
        </Menu>
      </React.Fragment>
      :
      <Menu pointing secondary>
        <Menu.Menu position='right'>
        {loading ?
          <Menu.Item style={{ backgroundColor: COMPANY_ACTIVE_BLUE, color: 'white' }}>
            <Icon 
            size='large' 
            name='circle notched' 
            loading
            inverted
            style={{ padding: 0, margin: 0 }}/>
          </Menu.Item>
          :
          <Menu.Item
            id='login-button'
            style={{ backgroundColor: COMPANY_ACTIVE_BLUE, color: 'white' }}
            name='login'
            onClick={() => loginWithRedirect()}
          />
        }
        </Menu.Menu>
      </Menu>}
    </NavBarWrapper>
  );
};

export default NavBar