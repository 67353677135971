import React, { useEffect, useState } from 'react'
import { Header, Icon, Image, Menu, Segment, Sidebar, Label, Popup } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'

import { useAuth0 } from '../../../react-auth0-spa'
import { useGlobalUI } from '../../contexts/GlobalUI';
import { FlexThis, HoverWrapper, HEADER_4_SIZE, COMPANY_EXTRA_DARK_BLUE, PageHeaderContainer } from '../../styled/styled';
import { useGlobalData } from '../../contexts/GlobalData';

function SideBar({ history }) {
  const { loansInfoMap, loanApplications, borrowerInfo } = useGlobalData();
  const { visible, isMobile, handleCurrentTabSemantic, currentTab, toggleVisibility } = useGlobalUI();

  return (
  <Sidebar
  as={Menu}
  animation='overlay'
  icon='labeled'
  vertical
  visible={visible}
  width='thin'>
    <Menu.Item>
      <Image
      label={isMobile && visible && { as: 'a', corner: 'right', icon: 'x', onClick: () => toggleVisibility() }}
      src={require('../../assets/wcplogo.png')} />
    </Menu.Item>
    <Menu.Item active={currentTab === 'additional routes'}>
      {borrowerInfo && borrowerInfo.roles && borrowerInfo.roles.includes('superadmin') &&
      <FlexThis>
        <Popup 
        basic
        position='top right'
        trigger={
        <HoverWrapper onClick={() => {
          history.push('/user-settings/'); 
          handleCurrentTabSemantic(null, { name: 'additional routes' });
        }}>
          <Icon name='user' />
        </HoverWrapper>
        }
        content='Edit profile and settings'
        style={{ border: '0.5px solid black' }} />
        <Popup 
        basic
        position='top right'
        trigger={
        <HoverWrapper onClick={() => {
          history.push('/notifications/'); 
          handleCurrentTabSemantic(null, { name: 'additional routes' });
        }}>
          <Icon name='bell' />
        </HoverWrapper>
        }
        content='View notifications'
        style={{ border: '0.5px solid black' }} />
      </FlexThis>}
      {borrowerInfo && <PageHeaderContainer style={{ fontSize: HEADER_4_SIZE }}>{borrowerInfo.borrowerFullName}</PageHeaderContainer>}
    </Menu.Item>
    <Menu.Item
    name='loans'
    active={currentTab === 'loans'}
    as={Link} 
    to='/loans/' 
    onClick={handleCurrentTabSemantic}
    icon>
      <PageHeaderContainer style={{ fontSize: 20 }}>Loans</PageHeaderContainer>
      <FlexThis>
        {/* <Icon name='home' size='big' /> */}
        {loansInfoMap && loansInfoMap.totalLoanCount > 0 && 
        <Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE, color: 'white', marginTop: 5 }}>{loansInfoMap.totalLoanCount}</Label>}
      </FlexThis>
    </Menu.Item>
    <Menu.Item 
    name='loan payments'
    active={currentTab === 'loan payments'}
    as={Link} 
    to='/loan-payments/' 
    onClick={handleCurrentTabSemantic}
    icon>
      {/* <Icon name='credit card outline' /> */}
      <PageHeaderContainer style={{ fontSize: 20 }}>Loan Payments</PageHeaderContainer>
      {loansInfoMap && loansInfoMap.totalLoanPaymentsCount > 0 && 
      <FlexThis><Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE, color: 'white', marginTop: 5 }}>{loansInfoMap.totalLoanPaymentsCount}</Label></FlexThis>}
    </Menu.Item>
    <Menu.Item 
    name='construction draws'
    active={currentTab === 'construction draws'}
    as={Link} 
    to='/construction-draws/' 
    onClick={handleCurrentTabSemantic}
    icon>
      {/* <Icon name='paper plane outline' /> */}
      <PageHeaderContainer style={{ fontSize: 20 }}>Construction Draws</PageHeaderContainer>
      {loansInfoMap && loansInfoMap.totalConstructionDrawsCount > 0 && 
      <FlexThis><Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE, color: 'white', marginTop: 5 }}>{loansInfoMap.totalConstructionDrawsCount}</Label></FlexThis>}
    </Menu.Item>
    <Menu.Item 
    name='payoffs'
    active={currentTab === 'payoffs'}
    as={Link} 
    to='/payoffs/' 
    onClick={handleCurrentTabSemantic}
    icon>
      {/* <Icon name='book' /> */}
      <PageHeaderContainer style={{ fontSize: 20 }}>Payoffs</PageHeaderContainer>
      {loansInfoMap && loansInfoMap.totalPayoffsCount > 0 && 
      <FlexThis><Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE, color: 'white', marginTop: 5 }}>{loansInfoMap.totalPayoffsCount}</Label></FlexThis>}
    </Menu.Item>
    <Menu.Item 
    name='loan applications'
    active={currentTab === 'loan applications'}
    as={Link} 
    to='/loan-applications/' 
    onClick={handleCurrentTabSemantic}
    icon>
      <PageHeaderContainer style={{ fontSize: 20 }}>Loan Applications</PageHeaderContainer>
      {loanApplications && 
      <FlexThis><Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE, color: 'white', marginTop: 5 }}>{loanApplications.length}</Label></FlexThis>}
    </Menu.Item>
    <Menu.Item 
    name='rewards'
    active={currentTab === 'rewards'}
    as={Link} 
    to='/rewards/' 
    onClick={handleCurrentTabSemantic}
    icon>
      <PageHeaderContainer style={{ fontSize: 20 }}>Rewards</PageHeaderContainer>
    </Menu.Item>
	<Menu.Item 
    name='help'
    active={currentTab === 'help'}
    as={Link} 
    to='/help/' 
    onClick={handleCurrentTabSemantic}
    icon>
      <PageHeaderContainer style={{ fontSize: 20 }}>Help</PageHeaderContainer>
    </Menu.Item>
  </Sidebar>
  )
}

export default withRouter(SideBar)