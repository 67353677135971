import React, { useState, useEffect, useRef } from 'react'
import { Icon } from 'semantic-ui-react'

import { useAuth0 } from '../../../react-auth0-spa'
import { PageHeaderContainer, HoverWrapper, RefreshIconWrapper } from '../../styled/styled'
import LoansInfoTable from '../../components/InfoTables/LoansInfoTable';

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function LoansPage({ getData }) {
  return(
    <React.Fragment>
      <LoansInfoTable getData={getData} />
    </React.Fragment>
  )
}

export default LoansPage