import React, { useState, useEffect } from 'react'
import { Segment, Form, Input, Button } from 'semantic-ui-react'

import { LOADER_SEGMENT_HEIGHT, PageHeaderContainer, InfoTableContainer, HEADER_3_SIZE } from '../../styled/styled'
import { useGlobalData } from '../../contexts/GlobalData'
import { useAuth0 } from '../../../react-auth0-spa'
import ImpersonationForm from '../../components/Forms/ImpersonationForm'

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function UserSettingsPage() {
  const INITIAL_FORM_VALUES = {}
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const { borrowerInfo, loading } = useGlobalData()

  const handleChange = (e, { name, value }) => setFormValues({ ...formValues, [name]: value })

  function handleSubmit() {
    console.log('USER ROLES: ', borrowerInfo.roles)
  }
  
  if (!borrowerInfo || loading) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  return(
    <React.Fragment>
      <InfoTableContainer>
        <PageHeaderContainer>User Settings</PageHeaderContainer>
        {/* IMPERSONATION FORM */}
        {borrowerInfo && borrowerInfo.roles && borrowerInfo.roles.includes('superadmin') && <ImpersonationForm />}
        <Form style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid black' }}>
          <PageHeaderContainer style={{ fontSize: HEADER_3_SIZE, color: 'black' }}>Edit your user subscriptions and update contact information. COMING SOON!</PageHeaderContainer>
        </Form>
      </InfoTableContainer>
    </React.Fragment>
  )
}

export default UserSettingsPage