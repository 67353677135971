import React, { useState, useEffect } from 'react'
import { Icon, Segment, Button } from 'semantic-ui-react'
import { 
  PageHeaderContainer, 
  InfoTableContainer, 
  COMPANY_PRIMARY_COLOR, 
  InfoCard,
  InfoCardLeftSide,
  FlexThis
} from '../../styled/styled'
import { withRouter } from 'react-router-dom'
import { useGlobalData } from '../../contexts/GlobalData'
import LoanInformationCard from '../../components/InfoTables/LoanInformationCard'
import PaymentsInnerInfoTable from '../../components/InfoTables/LoanPaymentsInfoTable/PaymentsInnerInfoTable'
import DrawsInnerInfoTable from '../../components/InfoTables/ConstructionDrawsInfoTable/DrawsInnerInfoTable'

function LoanDetailsPage(props) {
  const { loansMap } = useGlobalData()
  const loanId = props.match.params.loanId || null
  const [loanData, setLoanData] = useState()

  useEffect(() => {
    if(loanId && loansMap && !loanData) {
      setLoanData(loansMap[loanId])
      console.log('Details: ', loansMap[loanId])
    }
  }, [loansMap, loanData])
  
  return(
    <InfoTableContainer>
      <div style={{ display: 'flex' }}>
        <Button 
			primary
			onClick={() => props.history.push('/loans/')}><Icon name='angle double left' size='small' /> 
			go back
		</Button>
			{/*<a
        style={{ marginLeft: 'auto', textDecoration: 'underline' }}
        target='_blank'
href={`https://wcp-filebrowser.azurewebsites.net/redirect?e=loan&id=${loanId}`}>View Files <Icon name='external square alternate' /></a> */}
      </div>
      <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR, marginBottom: 10 }}>Loan Details</PageHeaderContainer>
      {loanData ? <LoanInformationCard data={loanData} /> : <Segment style={{ height: 300 }} loading />}
      {loanData &&
      <InfoCard style={{ textAlign: 'left' }}>
        {loanData.conditions}
      </InfoCard>}
      {loanData && loanData.LoanPayments.length > 0 && <PaymentsInnerInfoTable sortedLoanPaymentsArray={loanData.LoanPayments} />}
      {loanData && loanData.ConstructionDraws.length > 0 && <DrawsInnerInfoTable sortedDrawsArray={loanData.ConstructionDraws} title='Construction Draws'/>}
    </InfoTableContainer>
  )
}

export default withRouter(LoanDetailsPage)