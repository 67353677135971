import React, { useState, useEffect } from 'react'
import { Segment, Header, Icon } from 'semantic-ui-react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import NavBar from './components/NavBar/NavBar';
import { useAuth0 } from '../react-auth0-spa';
import { PageWrapper, LOADER_SEGMENT_HEIGHT, InfoTableContainer } from './styled/styled';
import Landing from './pages/landing';
import Login from './pages/login';
import LoansPage from './pages/loans';
import LoanPayments from './pages/loanPayments';
import ConstructionDrawsPage from './pages/constructionDraws';
import PayoffsPage from './pages/payoffs';
import LoanApplicationsPage from './pages/loanApplications';
import LoanDetailsPage from './pages/loanDetails';
import PayoffRequestPage from './pages/payoffs/PayoffsRequestPage';
import { useGlobalData } from './contexts/GlobalData';
import UserSettingsPage from './pages/userSettings';
import NotificationsPage from './pages/notifications';
import ImpersonationForm from './components/Forms/ImpersonationForm';
import RewardsPage from './pages/rewards/RewardsPage';
import AccessDenialPage from './pages/accessDenied/AccessDenialPage';
import Help from './components/Help'
   
function AppEntry({setSideBar}) {
  const { user, isAuthenticated, loading, getTokenSilently } = useAuth0();
  const { 
    setData, 
    error, 
    errorInfo, 
    impersonationEmail, 
    setLoading, 
    getAdditionalUserData, 
    borrowerInfo, 
    clearGlobalData, 
    getUserIntercomHashAndInitialize,
    getData
  } = useGlobalData();
  const [inForeclosure, setInForeclosure] = useState(false)
  
  useEffect(() => {
    if (!isAuthenticated) {
      if (window.Intercom) window.Intercom('shutdown')
    }
  }, [isAuthenticated])

  useEffect(() => {
    async function getUserData() {
      setLoading(true)
      let data = await getData()
	  setInForeclosure(data.main.Loans.filter(l => l.statusCode == 976560020).length > 0)
	  setSideBar(data.main.Loans.filter(l => l.statusCode == 976560020).length == 0)
      if (data.success) {
        let actualData = data.main
        setData(actualData)
        setLoading(false)
      } else if (data.error) {
        let actualData = data.main
        setData(actualData)
        setLoading(false)
      }
    }
    if (!loading && user) {
      getUserData()
    }
  }, [loading, user, impersonationEmail])
  
  // redirect for payment link
  useEffect(() => {
    let locArr = window.location.pathname ? window.location.pathname.split('/') : null
    let payType = locArr ? locArr[1] : null

    console.log('Path arr: ', locArr)

    // redirect to old payment portal for /p and /d
    if (payType && payType.length === 1) {
      if(payType === 'p') {
        let payment_id = locArr[2]
        window.location.replace(`https://payment-link.azurewebsites.net/p/${payment_id}`)
      }

      if(payType === 'l') {
        let loan_id = locArr[2]
        window.location.replace(`https://payment-link.azurewebsites.net/l/${loan_id}`)
      }
      
      if(payType === 'd') {
        let contact_id = locArr[2]
        window.location.replace(`https://payment-link.azurewebsites.net/d/${contact_id}`)
      }
    }
  }, [])

  return(
    <PageWrapper>
      {loading ? // if auth0 returns loading
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
      :
      isAuthenticated ? // if auth0 returns authenticated = true
		inForeclosure ? 
			<AccessDenialPage />
			:
			<React.Fragment>
				{borrowerInfo && borrowerInfo.roles && borrowerInfo.roles.includes('superadmin') && error &&
				<InfoTableContainer>
				<ImpersonationForm />
				</InfoTableContainer>}
				{error ? // if server returns error true
				<Segment placeholder style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto', textAlign: 'center' }}>
				<Header icon>
					<Icon name='exclamation circle' color='red' />
					{errorInfo.message}
				</Header>
				</Segment>
				:
				<Switch>
				<Route path='/notifications/'><NotificationsPage /></Route>
				<Route path='/user-settings/'><UserSettingsPage /></Route>
				<Route path='/loans/'><LoansPage /></Route>
				<Route path='/loan-details/:loanId'><LoanDetailsPage /></Route>
				<Route path='/loan-payments/'><LoanPayments /></Route>
				<Route path='/construction-draws/'><ConstructionDrawsPage /></Route>
				<Route path='/payoffs/'><PayoffsPage /></Route>
				<Route path='/loan-applications/'><LoanApplicationsPage /></Route>
				<Route path='/payoff-request/'><PayoffRequestPage /></Route>
				<Route path='/rewards'><RewardsPage /></Route>
				<Route path='/help'><Help /></Route>
				<Redirect from='/' to='/loans/' />
				</Switch>}
			</React.Fragment>
		:
		<Switch>
			<Route path='/login/'>
			<Login />
			</Route>
			<Redirect from='/' to='/login/' />
		</Switch>}
    </PageWrapper>
  )
}

export default AppEntry