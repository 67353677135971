import React from 'react'
import {Card, Divider, List} from 'semantic-ui-react'

const TierCard = ({tier}) => <Card style={{ height: '300px', border: `1px solid ${tier.textColor}`, borderRadius: '10px', boxShadow: '2px 3px 2px 1px rgba(0, 0, 0, 0.2)', marginTop: '40%', overflow: 'hidden'}}>
	<Card.Content>
		<Card.Header style={{textAlign: 'center', color: tier.textColor}}>
			<h2>{tier.header}</h2></Card.Header>
		<div style={{height: '2px', backgroundColor: tier.textColor, width: '70%', margin: '10px auto'}}></div>
		<Card.Description>
			<div style={{fontWeight: 'bold'}}><strong>{tier.subHeader}</strong></div>
			<List>
			{
				tier.bulletin.map((v,i) => <List.Item key={'key' + i}>
												<div style={{fontSize: '1.5em', lineHeight: '1em', marginTop: '20%', }}>{v}</div>
												{(tier.parallel && i < tier.bulletin.length - 1) &&
													<div style={{paddingLeft: '35%', color: tier.textColor, fontWeight: 'bold', fontSize: '1.5em', lineHeight: '1em', marginTop: '20%', marginBottom: '0', }}>OR</div>
												}
											</List.Item>)											
			}
			</List>
			{
				tier.extra &&
				<div>
					<div style={{height: '2px', backgroundColor: tier.textColor, margin: '10px auto', width: '70%'}}></div>
					<div style={{fontWeight: 'bold'}}><strong>{tier.extra.subHeader}</strong></div>
					<List bulleted>
					{
						tier.extra.bulletin.map((v,i) => <List.Item key={'key' + i} style={{marginBottom: '5px'}}>
														{v}
													</List.Item>)
					}
					</List>
				</div>
			}
		</Card.Description>
	</Card.Content>
	
</Card>

export default TierCard