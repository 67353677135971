import React, { useEffect, useState } from 'react';
import { useAuth0 } from './react-auth0-spa'

import logo from './logo.svg';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import AppEntry from './app/AppEntry';
import { SiteWrapper, COMPANY_GREY_LIGHT } from './app/styled/styled';
import NavBar from './app/components/NavBar';
import SideBar from './app/components/SideBar';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalUIProvider } from './app/contexts/GlobalUI';
import { GlobalDataProvider } from './app/contexts/GlobalData';

function App() {
  const { isAuthenticated } = useAuth0()
  const [showSide, setShowSide] = useState(true)
 
  return (
    <Router>
      <GlobalDataProvider>
        <GlobalUIProvider>
		{showSide && <SideBar/>}
          <SiteWrapper style={{ backgroundColor: isAuthenticated ? COMPANY_GREY_LIGHT : 'white' }}>
            <NavBar />
            <AppEntry setSideBar={setShowSide} />
          </SiteWrapper>
        </GlobalUIProvider>
	  </GlobalDataProvider>
    </Router>
  );
}

export default App;