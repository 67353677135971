import React, { useEffect, useState } from 'react'
import {
  Grid,
  Image,
  Header,
  Button,
  Icon
} from 'semantic-ui-react'
import { PageHeaderContainer, LoginButton } from '../../styled/styled'
import { useAuth0 } from '../../../react-auth0-spa'


function Login() {
  const { isAuthenticated, loginWithRedirect, loading } = useAuth0();

  return(
    <React.Fragment>
      <PageHeaderContainer><Image src={require('../../assets/wcplogocolor.png')} style={{ height: 200, width: 420, margin: 30 }} /></PageHeaderContainer>
      <PageHeaderContainer>Please log in to continue.</PageHeaderContainer>
      <div style={{ width: '100%', margin: 'auto', padding: 10 }}>
      {loading ? 
      <LoginButton>
        <Icon name='circle notched' loading />
      </LoginButton>
      : 
      <LoginButton onClick={() => loginWithRedirect()}> Login </LoginButton>}
      </div>
    </React.Fragment>
  )
}

export default Login