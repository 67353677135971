import React, { useEffect, useState, useRef } from 'react'
import { Icon, Segment, Header, Button } from 'semantic-ui-react'

import { LOADER_SEGMENT_HEIGHT } from '../../styled/styled'
import LoanApplicationsInfoTable from '../../components/InfoTables/LoanApplicationsInfoTable'
import { useGlobalData } from '../../contexts/GlobalData'


function LoanApplicationsPage() {
  const { loansInfoMap, loading } = useGlobalData()

  if (!loansInfoMap || loading) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  return(
    <React.Fragment>
      <LoanApplicationsInfoTable />
    </React.Fragment>
  )
}

export default LoanApplicationsPage