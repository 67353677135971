import React, {useState, useEffect} from 'react'
import { Icon, Popup, Button, Grid, Segment, Label, Input, TextArea, Checkbox, Dimmer, Loader} from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';
import FileBase64 from 'react-file-base64'
import '../style.css'
import { 
  InfoTable, 
  FlexThis, 
  LoanPaymentTab as DrawTabStyled, 
  LoanPaymentTabTitle as DrawTabTitle, 
  InfoTableContainer as TabsContainer, 
  POSITIVE_PASTEL_GREEN,
  UPCOMING_PASTEL_YELLOW,
  InnerTableContainer,
  PageHeaderContainer
} from '../../../styled/styled'

// the actual table inside each tab
const DrawsInnerInfoTable = ({ 
		sortedDrawsArray, 
		title, 
		eligible, 
		balance, 
		disbursed, 
		id, 
		borrowerId, 
		borrower, 
		borrowerEntityId,
		dataSource,
		setDataSource
	}) => {
	const [addDraw, setAddDraw] = useState(false)
	const [createfee, setCreatefee] = useState('')
	const [files, setFiles] = useState(undefined)
	const [inspection, setInspection] = useState('')
	const [amount, setAmount] = useState('')
	const [usernotes, setUsernotes] = useState('')
	const [contactnotes, setContactnotes] = useState('')
	const [loading, setLoading] = useState(false)
	const [draws, setDraws] = useState([...sortedDrawsArray])
	const [adder, setAdder] = useState(eligible) //ineligible loans will not add draws
	const [failure, setFailure] = useState(false)
	
	const [inspectiondate_error, setInspectiondate_error] = useState(false)
	const [requestamount_error, setRequestamount_error] = useState(false)
	const [file_error, setFile_error] = useState(false)
	
	const [inspection_msg, setInspection_msg] = useState('')
	const [amount_msg, setAmount_msg] = useState('')
	const [file_msg, setFile_msg] = useState('')
	
	const set_file_info = (files) =>{
		setFiles(files)
		validate_file(files)
	}
	
	const validate_file = (files) => {
		if (files){
			if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'].includes(files.file.type))
			{setFile_error(true); setFile_msg('only PDF or EXCEL files are allowed'); setFiles(undefined); return false}
			else if (files.file.size > 2097152)
			{setFile_error(true); setFile_msg('file size exceeding 2MB limit'); setFiles(undefined); return false}
			else {setFile_error(false); setFile_msg(''); return true}
		}else{setFile_error(false); setFile_msg(''); return true}
	}
	
	const validate_inspection = (inspectiondate) => {
		if (!inspectiondate)
		{setInspectiondate_error(true); setInspection_msg('value is required'); return false}
		else 	
		{
			var inputs = inspectiondate.split('-')
			var inputdate = new Date(parseInt(inputs[0]), parseInt(inputs[1])-1, parseInt(inputs[2]))
			var today = new Date()
			var current_date = new Date(today.getFullYear(), today.getMonth(), today.getDate())
			var expected_date = new Date(current_date.setDate(current_date.getDate() + 7))
			if (inputdate < expected_date)
			{setInspectiondate_error(true); setInspection_msg('value should be at least 7 days later'); return false}
			else{setInspectiondate_error(false);setInspection_msg(''); return true}
		}
	}
	
	
	const validate_amount = (inputamount) => {
		if (!inputamount)
		{
			setRequestamount_error(true);
			setAmount_msg('value is required')
			return false
		}
		else{
			var digital_amount = /^\d+$/.test(inputamount.replace(/[,.$]/g, ''))
		
			if (!digital_amount)
			{setRequestamount_error(true); setAmount_msg('invalid amount value'); return false}
			else if (parseFloat(inputamount.replace(/[,$]/g, '')) > parseFloat(balance.replace(/[,$]/g, '')))
			{setRequestamount_error(true);setAmount_msg('Requesting more than current balance'); return false}
			else {setRequestamount_error(false);setAmount_msg(''); return true}
		}
	}
	
	const format_dollar = (amt) => {
		if (!/^\s*\d+\s*$/.test(amt.replace(/[,.$]/g, ''))) return amt
		var result = amt.replace(/[,\s$]+/g, '').replace(/(?<=\d)(?=(?:\d\d\d)+(?:\.|$))/g, ',')
		if (result.includes('.')){
			var results = result.split('.')
		    return '$' + results[0] + '.'+results[1].replace(/,/g, '')
		}
		return '$' + result
	}
	
	const handle_submit = async () =>  {
		if(validate_inspection(inspection) & validate_amount(amount) & validate_file(files)){
			setLoading(true)
			var submit_url = 'https://borrowers-portal.azurewebsites.net/api/CreateConstructionDrawOnRequest?code=XxUTMwQMiR3lx379jn543R8lq/fH01soZlNGebJ1TbAwYZgsYzTi8g=='
			//var submit_url = 'http://localhost:7071/api/CreateConstructionDrawOnRequest'
			var res = await fetch(submit_url,
				{
					method: 'POST', 
					contentType: 'application/json, application/pdf',
					body: JSON.stringify({
						loanId: id,
						requestedDate: inspection,
						borrowerId: borrowerId,
						borrowerName: borrower,
						requestedAmount: amount.replace(/[,$]/g, ''),
						createFee: createfee,
						userNotes: usernotes,
						contactNotes: contactnotes,
						borrowerEntity: borrowerEntityId,
						files: files
					})
				})
				
			if(res.status >= 200 && res.status < 300){
				var today = new Date()
				var data = await res.json()
				console.log(data)
				var newDraw = {
					id: data.drawId,
					LoanId: id,
					name: data.drawName,
					dateRequested: today,
					accessInformation: '',
					lockBoxCode: '',
					contractorEmailAddress: '',
					nameOfContractor: '',
					lienReleaseThroughtDate: '',
					totalDrawAmount: 0,
					drawAmount: amount.replace(/[,$]/g, ''),
					inspector: '',
					state: 'Active',
					statusCode: 0,
					statusCodeText: 'Submitted',
					approvedOn: ''
				}
				if(data.feeId){
					var newfee = {
						loanid: id,
						feeid: data.feeId,
						feeType: 799520002, 
						feeName: data.feeName, 
						feeTypeName: 'ConstructionDraw', 
						feeAmount: 300
					}
				
					var fees = [...dataSource.fees, newfee]
					setDataSource({...dataSource, fees: [...fees]})
				}
				setDraws([newDraw, ...draws])
				setAdder(false);
				setAddDraw(false)
				setFailure(false)
				setLoading(false)
			}
			else {setLoading(false); setFailure(true)}
		}
	}
	
	return (
		<InnerTableContainer style={{marginBottom: '0px'}}>
			<PageHeaderContainer style={{ fontSize: 20, marginBottom: 5 }}>{title}</PageHeaderContainer>
			{
				adder &&
				<div style={{display: 'flex', justifyContent: 'right' }}>
					<Button primary onClick={() => setAddDraw(!addDraw)}>
						{addDraw ? 'Cancel' : 'Add New Draw'}
					</Button>
				</div>
			}
			{
				addDraw ?
				<div>
					<Grid style={{paddingLeft: '5px'}}>
						<Grid.Row style={{marginLeft: '1px', }}>
						Please allow 7-10 days for this process to be completed. Funds will be sent 2-3 business days after approval of the inspection report.<br /> If you have questions about the process or next steps then please reach out to draws@wcp.team.
						</Grid.Row>
						<Grid.Row style={{marginLeft: '1px', paddingTop: '0'}}>
							<Grid.Column width={8} style={{paddingLeft: '0'}}>
								Balance: {balance}
							</Grid.Column>
							<Grid.Column width={8} style={{textAlign: 'right'}}>
								Disbursed: {disbursed}
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{adder ? 
						failure ? 
						<Grid style={{background: 'none', paddingRight: '0'}}>
							<Grid.Row style={{padding: '0', marginLeft: '1px'}}>
								<span style={{color: 'red'}}>Something went wrong. Please try again later</span>
							</Grid.Row>
						</Grid>
						:
						<Grid columns={2} style={{background: 'none', paddingRight: '0'}}>
							{loading && <div className='Loader'>
								<Dimmer active inverted size='massive'>
									<Loader inverted>Loading</Loader>
								</Dimmer>
							</div>}
							<Grid.Row style={{padding: '0', marginLeft: '1px'}}>
							<Grid.Column width={8} style={{padding: '0'}}>
								<Segment style={{padding: '1px 0px 0px 0px'}}>
									<Label attached='top' style={{color: inspectiondate_error? 'white': 'black', background: inspectiondate_error? 'red':'#b3f9b5', fontSize: '14px'}}>
										Requested Inspection Date
										{inspectiondate_error ? ': '+inspection_msg : ''}
									</Label>
									<Input type='date' 
										style={{width: '100%', height: '38px'}} 
										value={inspection} 
										onChange = {(e) =>{setInspection(e.target.value); validate_inspection(e.target.value)}}/>
								</Segment>
							</Grid.Column>
							<Grid.Column width={8} style={{padding: '0'}}>
								<Segment style={{padding: '1px 0px 0px 0px'}}>
									<Label attached='top' style={{color: requestamount_error? 'white': 'black', background: requestamount_error? 'red':'#b3f9b5', fontSize: '14px'}}>Requested Amount{requestamount_error ? ': '+ amount_msg : ''}</Label>
									<Input type='text' style={{width: '100%', height: '38px'}} value={amount} onChange={(e) => {setAmount(format_dollar(e.target.value)); validate_amount(e.target.value)}}/>
								</Segment>
							</Grid.Column>
							</Grid.Row>
							<Grid.Row style={{padding: '0', marginLeft: '1px', marginTop: '10px'}}>
							<Grid.Column width={8} style={{padding: '0'}}>
								<Segment style={{padding: '1px 0px 0px 0px'}}>
									<Label attached='top' style={{color: 'black', background: '#b3f9b5', fontSize: '14px'}}>Notes</Label>
									<TextArea className='grid-textarea' rows={4} onChange={e => setUsernotes(e.target.value)}/>
								</Segment>
							</Grid.Column>
							<Grid.Column width={8} style={{padding: '0'}}>
								<Segment style={{padding: '1px 0px 0px 0px'}}>
									<Label attached='top' style={{color: 'black', background: '#b3f9b5', fontSize: '14px'}}>Contact Info for Site</Label>
									<TextArea className='grid-textarea' rows={4} onChange={e => setContactnotes(e.target.value)}/>
								</Segment>
							</Grid.Column>
							</Grid.Row>
							<Grid.Row style={{padding: '0', marginLeft: '1px', marginTop: '10px'}}>
								<Grid.Column width={8}>
									<Label attached='top' style={{color: 'black', background: '#b3f9b5', fontSize: '14px', borderRadius: '4px'}}>Will you be using reward points for this Draw Request Fee?</Label>
								</Grid.Column>
								<Grid.Column width={4} style={{paddingTop: '5px'}}>
									<Checkbox radio label='Yes' name='useForFee' value={createfee} onChange={() => setCreatefee(true)} checked={createfee === true}/>
								</Grid.Column>
								<Grid.Column width={4} style={{paddingTop: '5px'}}>
									<Checkbox radio label='No' name='useForFee' value={createfee} onChange={() => setCreatefee(false)} checked={createfee === false}/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row style={{padding: '0', marginLeft: '1px', marginTop: '14px'}}>
								<Grid.Column width={12} className = {file_error ? 'grid-file-error' : 'grid-file'}>
									<FileBase64 style={{width: '100%', fontSize: '12px'}} onDone={set_file_info} value={files} />
									{file_error ? <span style={{color: 'white', marginLeft: '15px'}}>{file_msg}</span> : ''}
								</Grid.Column>
								<Grid.Column width={4} style={{padding: '0'}}>
									<Button 
										primary 
										content='Submit' 
										style={{width: '100%'}}
										onClick= {handle_submit}
										disabled={file_error || inspectiondate_error || requestamount_error}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						:
						''
					}
				</div>
				:
				<div>
				<Grid colums={6} >
					<Grid.Row className='grid'>
						<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
							Date Requested
						</Grid.Column>
						<Grid.Column style={{width: '19%'}}>
							Name
						</Grid.Column>
						<Grid.Column style={{width: '20%'}}>
							Amount ($)
						</Grid.Column>
						{/*	<Grid.Column style={{width: '16%'}}>
							Inspector
			</Grid.Column> */}
						<Grid.Column style={{width: '24%'}}>
							Status
						</Grid.Column>
						<Grid.Column style={{width: '16%'}}>
							Actions
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{
					draws && draws.map((draw, i) =>
					<Grid colums={6} key={i}>
						<Grid.Row className="grid-row">
							<Grid.Column style={{width: '20%', paddingLeft: '20px'}}>
								{moment.utc(draw.dateRequested).format('MM/DD/YYYY')}
							</Grid.Column>
							<Grid.Column style={{width: '19%'}}>
								{draw.name}
							</Grid.Column>
							<Grid.Column style={{width: '20%'}}>
								${numeral(draw.drawAmount).format('0,0.00')}
							</Grid.Column>
							{/*	<Grid.Column style={{width: '16%'}}>
								{draw.inspector}
				</Grid.Column> */}
							<Grid.Column style={{width: '24%'}}>
								{draw.statusCodeText}
							</Grid.Column>
							<Grid.Column style={{width: '16%'}}>
								<FlexThis style={{position: 'relative', top: '-8px'}}>
								<Popup 
								content='Go to draw details'
								trigger={
									<Button 
									as='a'
									href={`https://wcp-filebrowser.azurewebsites.net/redirect?e=cdraw&id=${draw.id}`}
									target='_blank'
									icon
									primary
									fluid
									labelPosition='right'>Files <Icon name='folder' /></Button>
								} />
								</FlexThis>
							</Grid.Column>
						</Grid.Row>
					</Grid>)
				}
				</div>
			}
		</InnerTableContainer>
	)
}

export default DrawsInnerInfoTable