import React, { useState, useEffect } from 'react'
import { Segment, Form, Input, Button } from 'semantic-ui-react'

import { LOADER_SEGMENT_HEIGHT, PageHeaderContainer, InfoTableContainer, InnerTableContainer, HEADER_3_SIZE } from '../../styled/styled'
import { useGlobalData } from '../../contexts/GlobalData'
import { useAuth0 } from '../../../react-auth0-spa'

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function NotificationsPage() {
  const { borrowerInfo, loading } = useGlobalData()
  
  if (!borrowerInfo || loading) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  return(
    <React.Fragment>
      <InfoTableContainer>
        <PageHeaderContainer style={{ marginBottom: 10 }}>Notifications</PageHeaderContainer>
        <InnerTableContainer>
          <PageHeaderContainer style={{ fontSize: HEADER_3_SIZE, color: 'black' }}>View important notifications. COMING SOON!</PageHeaderContainer>
        </InnerTableContainer>
      </InfoTableContainer>
    </React.Fragment>
  )
}

export default NotificationsPage