import React from 'react'
import numeral from 'numeral'
import moment from 'moment'

import { InfoCard, InfoCardLeftSide, InfoCardRightSide } from '../../styled/styled'
import { GetLoanStatusText } from '../../lib/helpers'

function LoanInformationCard({ data }) {
  return(
    <InfoCard>
      {/* LEFT SIDE */}
      <InfoCardLeftSide>
        <p>Loan Name:<br />
        Current Status:<br />
        Loan Amount:<br />
        Interest Rate:<br />
        {/* Current Loan Balance:<br /> */}
        Current Construction Balance:<br />
        Construction Disbursed To Date:<br />
        Maturity Date:<br />
        Loan Servicer:</p>
      </InfoCardLeftSide>

      {/* RIGHT SIDE */}
      <InfoCardRightSide>
        <p>{data.name}<br />
        {data.statusCode ? GetLoanStatusText(data.statusCode) : 'N/A'}<br />
        ${numeral(data.WCPLoanAmount).format('0,0.00')}<br />
        {data.interestRate}%<br />
        {/* ${numeral(data.amount).format('0,0.00')}<br /> */}
        ${numeral(data.availableFunds).format('0,0.00')}<br />
        ${numeral(data.constructionFundAmount - data.constructionFundBalance).format('0,0.00')}<br />
        {moment.utc(data.maturityDate).format('MM/DD/YYYY')}<br />
        {data.loanServicer || 'N/A'}</p>
      </InfoCardRightSide>
      <div style={{ clear: 'both', textAlign: 'left', paddingTop: 10 }}></div>
    </InfoCard>
  )
}

export default LoanInformationCard