import React, { useEffect, useState, useRef } from 'react'
import { Icon, Segment, Header, Button } from 'semantic-ui-react'

import { LOADER_SEGMENT_HEIGHT } from '../../styled/styled'
import PayoffsInfoTable from '../../components/InfoTables/PayoffsInfoTable'
import { useGlobalData } from '../../contexts/GlobalData'

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function PayoffsPage() {
  const { loansInfoMap, loading } = useGlobalData()
  
  if (!loansInfoMap || loading) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  return(
    <React.Fragment>
      <PayoffsInfoTable />
    </React.Fragment>
  )
}

export default PayoffsPage