/**
 *                      Whether or not the borrower has paid, status of payment according to WCP.
 * 
 * @param {int} paymentStatusCode - 9 digit number that indicates what the payment status is:: REQUIRED
 * 
 */
export const GetPaymentStatusText = (paymentStatusCode) => {
  switch(paymentStatusCode) {
    case 799520000:
      return "Unpaid"
    case 799520010:
      return "Canceled"
    case 799520011:
      return "Missed"
    case 2:
      return "Paid"
    default:
      return "N/A"
  }
}

/**
 *                      status of redemption
 * 
 * @param {int} redemptionStatusCode - 9 digit number that indicates what the payment status is:: REQUIRED
 * 
 */
export const GetRewardRedemptionStatus = (redemptionStatusCode) => {
  switch(redemptionStatusCode) {
    case 799520001:
      return "Under Review"
    case 799520002:
      return "Approved"
    case 799520003:
      return "Rejected"
    default:
      return "Submitted"
  }
}

/**
 *                        Status of the payment as reported by Stripe (Scheduled/Unscheduled etc.)
 * 
 * 
 * @param {int} scheduledPaymentStatusCode - 9 digit number that indicates what the payment status is:: REQUIRED
 * 
 */
export const GetScheduledPaymentStatusText = (scheduledPaymentStatusCode) => {
  switch(scheduledPaymentStatusCode) {
    case 799520000:
      return "Unscheduled"
    case 799520102:
      return "ACH - Charged"
    case 799520103:
      return "ACH - Cleared"
    case 799520110:
      return "ACH - Failed"
    case 799520101:
      return "ACH - Scheduled"
    case 799520001:
      return "Check - Scheduled"
    default:
      return "N/A"
  }
  

}


/**
 *                        Status of the loan
 * 
 * 
 * @param {int} loanStatusCode - 9 digit number that indicates what the loan status is:: REQUIRED
 * 
 */
export const GetLoanStatusText = (loanStatusCode) => {
	let loanStatuses = {
		976560024: "Processing - Setup",
		976560000: "Processing - Underwriting", 
		976560002: "Processing - Doc Review",
		976560003: "Processing - Closing",
		976560004: "Servicing - Current",
		976560005: "Servicing - Default",
		976560006: "Servicing - Payoff Pending",
		976560007: "Paid Off",
		976560012: "File Closed Out - Borrower Request",
		976560013: "File Opened In Error",
		976560015: "Servicing - Delinquent",
		976560016: "REO",
		976560017: "Servicing - Matured",
		976560018: "Processing - Title Review",
		976560019: "Servicing - Late",
		976560020: "Servicing - Foreclosure",
		976560021: "30 Year Transfer",
		976560023: "Processing - Doc Collection"
	}
	
	return loanStatuses[loanStatusCode] || ''
	
}

// USE THIS FUNCTION FOR SORTING DATES IN DESCENDING ORDER
/**
 * 
 * @param {Date} ld1 
 * @param {Date} ld2 
 */
export const sortDatesDesc = (ld1, ld2) => {
  return (ld1 > ld2) ? -1 : (ld1 < ld2) ? 1 : 0
}

// USE THIS FUNCTION FOR SORTING DATES IN ASCENDING ORDER
/**
 * 
 * @param {Date} ld1 
 * @param {Date} ld2 
 */
export const sortDatesAsc = (ld1, ld2) => {
  return (ld1 > ld2) ? 1 : (ld1 < ld2) ? -1 : 0
}
