import React, { useState } from 'react'
import { Header, Form, Input, TextArea, Message, Button, Modal, Dimmer, Segment, Icon, Select, Loader } from 'semantic-ui-react'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { useAuth0 } from '../../../react-auth0-spa'

import { useGlobalData } from '../../contexts/GlobalData'
import { HEADER_3_SIZE, FlexThis, InfoTableContainer, PageHeaderContainer } from '../../styled/styled'
import { withRouter } from 'react-router-dom'

function PayoffRequestForm({ email, options, history }) {
  const { getTokenSilently } = useAuth0()
  const INITIAL_FORM_STATE = {  
    payoffRequestDate: new Date(),
    loanId: '',
    closingDate: new Date(),
    titleCompanyEmail: '',
    borrowerEmail: '',
    showModal: false,
    submitted: false,
    isLoading: false
  }
  const [formData, setFormData] = useState(INITIAL_FORM_STATE)

  const clearForm = () => setFormData(INITIAL_FORM_STATE)

  const handleDatePicker = (date, type) => {
    if(type == 'payoffRequestDate') {
      setFormData({ ...formData, payoffRequestDate: date })
    } else {
      setFormData({ ...formData, closingDate: date })
    }
  }

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value })
  }

  async function submitForm() {
    const { loanId, payoffRequestDate, closingDate, titleCompanyEmail, borrowerEmail, addNotes } = formData
    const accesstoken = await getTokenSilently()

    let domainType = 'prod'

    if (process.env.NODE_ENV === 'development') {
      domainType = 'dev'
    }

    const submitObj = {
      loanId,
      email,
      payoffRequestDate,
      closingDate,
      titleCompanyEmail,
      borrowerEmail,
      accesstoken,
      domainType,
      borrowerPortalNotes: addNotes
    }

    setFormData({ ...formData, isLoading: true })

    console.log(JSON.stringify(submitObj))

    const SUBMIT_URL = 'https://borrowers-portal.azurewebsites.net/api/CreatePayoffRequest?code=kZUQt9fI40je7RXBXWak/c7AUI5/e410GqUWmewMh8NnNBNcyRpmHA=='

    try {
      let req = await fetch(SUBMIT_URL, {
                  method: 'POST',
                  body: JSON.stringify(submitObj),
                  headers: {
                    'content-type': 'application/json'
                  },
                })
      
      if (req.status >= 200 && req.status < 300) {
        // success
        setFormData({
          ...formData,
          submitted: true,
          isLoading: false
        })
      } else {
        setFormData({
          ...formData,
          hasError: true,
          isLoading: false
        })
      }
    } catch (err) {
      setFormData({
        ...formData,
        hasError: true,
        isLoading: false
      })
    }
  }

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InfoTableContainer>
        <div style={{ width: 'fit-content', marginBottom: 10 }}>
			<Button 
				primary
				onClick={() => history.push('/payoffs/')}><Icon name='angle double left' size='small' /> 
				go back
			</Button>
        </div>
        {formData.isLoading ?
        <React.Fragment>
          <div>
            <Segment loading style={{ height: 200 }} />
          </div>
        </React.Fragment>
        :
        formData.submitted ?
        <React.Fragment>
          <div style={{ padding: 25, textAlign: 'center' }}>
            <Message 
              success
              header='Your submission was successful'
              content='Your Payoff request is being reviewed.'
            />
            <Button primary size='large' onClick={() => clearForm()}>Okay</Button>
          </div>
        </React.Fragment>
        :
        formData.hasError ?
        <React.Fragment>
          <div style={{ padding: 25, textAlign: 'center' }}>
            <Message 
              negative
              header='Error Submitting Your Request'
              content='Please stand by, our technology personnel are looking into this.'
            />
            <Button primary size='large' onClick={() => clearForm()}>Okay</Button>
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <PageHeaderContainer style={{ fontSize: HEADER_3_SIZE, color: 'black', marginBottom: 10 }}>
            Please allow ~10 business days for this request to be processed and approved. 
            Each request is $50 but if you would like it expedited (within the same week, less than 5 days) it will be $200.
          </PageHeaderContainer>
          <Form>
            <Form.Field
              required
              control={Select}
              label='Select Address'
              name='loanId'
              options={options}
              onChange={handleChange}
              placeholder={'Please select an address'}
            />

            <Form.Group widths='equal'>
              <Form.Field>
                <Header as='h5'>Closing Date</Header>
                <KeyboardDatePicker
                  margin="normal"
                  style={{ width: '100%' }}
                  id="closing-date-dialog"
                  format="MM/dd/yyyy"
                  value={formData.closingDate}
                  onChange={date => handleDatePicker(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Form.Field>

              <Form.Field>
                <Header as='h5'>Payoff Good Through Date</Header>
                <KeyboardDatePicker
                  margin="normal"
                  style={{ width: '100%' }}
                  id="payoff-good-thru-dialog"
                  format="MM/dd/yyyy"
                  value={formData.payoffRequestDate}
                  onChange={date => handleDatePicker(date, 'payoffRequestDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field 
                control={Input}
                label='Title Company Email'
                name='titleCompanyEmail'
                onChange={handleChange}
                required
              />
              <Form.Field 
                control={Input}
                label='Borrower Email'
                name='borrowerEmail'
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Field 
              control={TextArea}
              label='Additional Notes'
              name='addNotes'
              onChange={handleChange}
              required
            />
            <FlexThis>
              <Button 
              size='large' 
              style={{ width: 300 }}
              onClick={() => submitForm()} 
              primary>Submit</Button>
            </FlexThis>
          </Form>
        </React.Fragment>}
      </InfoTableContainer>
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(PayoffRequestForm)