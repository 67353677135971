import React, { useState, useContext, useEffect } from "react";
import { useAuth0 } from '../../react-auth0-spa';
import { GetPaymentStatusText, GetLoanStatusText } from "../lib/helpers";

export const GlobalDataContext = React.createContext();
export const useGlobalData = () => useContext(GlobalDataContext);

export const GlobalDataProvider = ({ children }) => {
  const { getTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [borrowerInfo, setBorrowerInfo] = useState()
  const [loans, setLoans] = useState()
  const [constructionDraws, setConstructionDraws] = useState()
  const [loansMap, setLoansMap] = useState()
  const [loansInfoMap, setLoansInfoMap] = useState() // use this for getting the loan payments info (late, paid, upcoming payments)
  const [loanApplications, setLoanApplications] = useState()
  const [error, setError] = useState(false)
  const [errorInfo, setErrorInfo] = useState()
  const [unverifiedEmail, setUnverifiedEmail] = useState('')
  const [impersonationEmail, setImpersonationEmail] = useState()
  const [allData, setAllData] = useState(null)
  const [hasFishLoans, setHasFishLoans] = useState(false)
  /**
   *                  get loans by borrower
   * 
   * @param {function} setDataFunction - the function to set data on that page.
   * @param {function} getTokenFunction - the function to get token from auth0.
   */

  function fillLoansInfoMap(loansArray) {
    let tempLoanMap = {
      totalLoanCount: loansArray.length
    }
    let payoffsArray = []
    let constructionDrawsArray = []

    for (let loan of loansArray) {
      // these are arrays within loan
      const { ConstructionDraws, Payoffs } = loan
      let LoanPayments = loan.LoanPayments

      // initialize loan info for current
      tempLoanMap[loan.id] = {}

      // get information regarding payoffs
      if (Payoffs && Payoffs.length > 0) {
        payoffsArray = [...payoffsArray, ...Payoffs] // create an array of all payoffs
      }

      // get information regarding construction draws
      if (ConstructionDraws && ConstructionDraws.length > 0) {
        constructionDrawsArray = [...constructionDrawsArray, ...ConstructionDraws] // create an array of all construction draws
      }

      // count active loans
      if(GetLoanStatusText(loan.statusCode).toLowerCase().includes('processing') || GetLoanStatusText(loan.statusCode).toLowerCase().includes('servicing')) {
        tempLoanMap.totalActiveLoansCount = (tempLoanMap.totalActiveLoansCount || 0) + 1
      }

      // count paid off loans
      if (GetLoanStatusText(loan.statusCode) === 'Paid Off') tempLoanMap.totalPaidOffLoansCount = (tempLoanMap.totalPaidOffLoansCount || 0) + 1

      // get information regarding loan payments on active loans only, exclude paid off
      if (GetLoanStatusText(loan.statusCode) !== 'Paid Off' && LoanPayments.length > 0) {
        LoanPayments = LoanPayments.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        
        const CURRENT_DATE = new Date()

        for (let loanPayment of LoanPayments) {
          
          let dueDate = new Date(loanPayment.paymentDueOn)
         
          if (dueDate < CURRENT_DATE) {// potentially late
            if (GetPaymentStatusText(loanPayment.paymentStatus) === 'Paid') {//not late
              tempLoanMap[loan.id].hasPaidPayments = true //flag if any paid payments on loan
              tempLoanMap[loan.id].paidPaymentsCount = (tempLoanMap[loan.id].paidPaymentsCount || 0) + 1 //count how many paid payments on loan
              tempLoanMap.totalPaidPaymentsCount = (tempLoanMap.totalPaidPaymentsCount || 0) + 1 //count total number of paid payments
            } else {// late
              tempLoanMap[loan.id].hasLatePayments = true // flag if any late payments on loan
              tempLoanMap[loan.id].latePaymentsCount = (tempLoanMap[loan.id].latePaymentsCount || 0) + 1 //count how many late payments on loan
              tempLoanMap.totalLatePaymentsCount = (tempLoanMap.totalLatePaymentsCount || 0) + 1 //count total number of late payments
            }
          } else {// unscheduled
            tempLoanMap[loan.id].hasUnscheduledPayments = true //flag if any unscheduled payments
            tempLoanMap[loan.id].unscheduledPaymentsCount = (tempLoanMap[loan.id].unscheduledPaymentsCount || 0) + 1 //count how many unscheduled payments on loan
            tempLoanMap.totalUnscheduledPaymentsCount = (tempLoanMap.totalUnscheduledPaymentsCount || 0) + 1 //count total number of unscheduled payments
          }
        }
      }
    }
    // total number of loan payments on account
    tempLoanMap.totalLoanPaymentsCount = tempLoanMap.totalPaidPaymentsCount + tempLoanMap.totalUnscheduledPaymentsCount + tempLoanMap.totalLatePaymentsCount

    tempLoanMap.constructionDrawsArray = constructionDrawsArray // create array of all construction draws
    tempLoanMap.totalConstructionDrawsCount = constructionDrawsArray.length // total number of cdraws

    tempLoanMap.payoffsArray = payoffsArray // create array of all payoffs
    tempLoanMap.totalPayoffsCount = payoffsArray.length // total number of payoffs on account

    setLoansInfoMap(tempLoanMap)
  }

  // this gets called when we do get data
  function setData(data) {
    clearGlobalData()
    setLoading(true)

    setAllData(data) // sets full object in one var
	setHasFishLoans(data.hasFishLoans)
    if (data.Contact) {
      setBorrowerInfo(data.Contact)
    }

    console.log('Set Contact')

    if (data.LoanApplications && data.LoanApplications.length > 0) {
      setLoanApplications(data.LoanApplications)
    }

    console.log('Set Loan Applications')

    if (data.Loans && data.Loans.length > 0) {
      setLoans(data.Loans)

      let _loansMap = {}
      data.Loans.map((loan, i) => _loansMap[loan.id] = loan)
      setLoansMap(_loansMap)
      fillLoansInfoMap(data.Loans)
    }

    console.log('Set Loans')
    
    if (data.error) {
      setErrorInfo({
        message: data.msg,
        type: data.type
      })
      setError(true)
    } else {
      setError(false)
    }

    console.log('Set Error')

    setLoading(false)
  }

  const handleImpersonationEmail = (email) => setImpersonationEmail(email)

  // return borrowerInfo.roles these roles come from auth0 rules
  async function getAdditionalUserData(token) {
    let domain = process.env.NODE_ENV === 'development' ? 'https://washingtoncapitalpartners.auth0.com' : 'https://wcp.auth0.com'
	
	//let domain = 'https://washingtoncapitalpartners.auth0.com' 
	let superadmins = ['XZhao@wcp.team', 'xzhao@wcp.team', 'tdetwiler@wcp.team', 'tthampan@wcp.team', 'rcordova@wcp.team', 'NPerez@wcp.team', 'CCampos@wcp.team', 'aarbuckle@wcp.team']
    try {
      let res = await fetch(domain + '/userinfo', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
	
	  
      if (res.status >= 200 && res.status < 300) {
        let data = await res.json()
		console.dir(data)
    //    let rolesKey = process.env.NODE_ENV === 'development' ? 'https://washingtoncapitalpartners:auth0:com/roles' : 'https://wcp:auth0:com/roles'
    //    
    //    console.log('User roles: ', data[rolesKey])
	//
    //    return data[rolesKey]
		  return superadmins.includes(data['email']) ? 'superadmin' : data['email_verified'] ? 'borrower' : '' 
      }
    } catch (err) {
      console.log('ERROR: ', err)
    }
  }

  // return borrowerInfo.userIntercomHash and activates intercom
  async function getUserIntercomHashAndInitialize(name, email) {
    const SUBMIT_URL = 'https://borrowers-portal.azurewebsites.net/api/GetUserIntercomHash?code=AUGdGIPeN7DOPd4PALGdRqkP6DXAL4wwoBxEfpGMGLAxkr8AC1torg=='
    let userHashRequest = await fetch(SUBMIT_URL, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ email })
    })

    if (userHashRequest.status >= 200 && userHashRequest.status < 300) {
      let userHashResponse = await userHashRequest.json()

      // initialize intercom
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: 'jogpe1qi',
          email,
          name,
          updated_at: Date.now(),
          user_hash: userHashResponse.hashToString,
        })
      }

      return userHashResponse
    }
  }

  const clearGlobalData = () => {
    setError(false)
    setLoansInfoMap(null)
    setLoans(null)
    setLoanApplications(null)
  }

  // main get data function
  async function getData() {
    clearGlobalData() // clear to refresh
    const access_token = await getTokenSilently()

    let reqBody = {
      accesstoken: access_token,
      domainType: process.env.NODE_ENV,
      impersonationEmail: impersonationEmail || null
    }

    console.log('making request... ', JSON.stringify(reqBody))

    let functionUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:7071/api/borrower-loans' : 'https://borrowers-portal.azurewebsites.net/api/borrower-loans?code=/9vFWiuxyD6IGZtaISDqHvqRWHTgUg4EPNd89Dfo082deUWEw6ywnw=='
    let req = await fetch(functionUrl, {
      method: 'post',
      body: JSON.stringify(reqBody)
    })
 
    if (req.status >= 200 && req.status < 300) {
      let d = await req.json()
	  console.log("here is the loan data")
	  console.dir(d)
      // update contact to include auth0 roles
      // update contact to include user intercom hash
	  if(d.error){
		if (d.msg == 'Contact is not authorized.'){
			setUnverifiedEmail(d.email)
		}
	  }
      else if(d.Contact) {
        console.log('Got contact info: ', d.Contact)
        d.Contact.userIntercomHash = await getUserIntercomHashAndInitialize(d.Contact.borrowerFullName, d.Contact.email)
      } else {
        d.Contact = {} // initialize for admins but contact doesn't exist
      }

      d.Contact.roles = await getAdditionalUserData(access_token)

      console.log('Got data: ', d)
      return ({
        success: true,
        main: d
      })
    } else {
      let d = {
        error: true,
        type: 'request',
        msg: 'Error getting data. Please refresh the page and try again or contact dev@wcp.team',
        Contact: {}
      }
      d.Contact.roles = await getAdditionalUserData(access_token)
      console.log('Error: ', req)
      return d
    }
  }
  return unverifiedEmail ? <div className="wcp_container">
				<div className="wcp_logo">
					<img src="/wcplogo_small.png" /> 
				</div>	
				<div className="wcp_content">
					<div>
						<p className="salute">Dear Valued Borrower,</p>
						<p>We found that you have not verified your email.</p>
						<p>Before you can access the WCP Borrower Portal, </p>
						<p>You need to verify your account by following the instructions that were sent to your email,</p>
						<p>{unverifiedEmail}, during the sign-up process.</p>
						<p className="salute"></p>
						<p style={{marginTop: '0'}}>If you still have questions then please contact us at (703) 722-0774 or <a href='mailto:servicing@wcp.team'>servicing@wcp.team</a></p>
						<p className="salute">Thank you for your cooperation, and we look forward to assisting you further.</p>
						<p>Sincerely,</p>
						<p>Washington Capital Partners</p>
					</div>
				</div>
			</div>: <GlobalDataContext.Provider
      value={{
        loans,
        loansMap, // lookup table by loan id
        loansInfoMap, // doesn't contain dynamics loans info, only metadataish info
        constructionDraws,
        setData,
        borrowerInfo, // basically data.contact
        loanApplications,
        loading,
        error,
        setError,
        errorInfo,
        setErrorInfo,
        impersonationEmail,
        handleImpersonationEmail,
        setLoading,
        getAdditionalUserData,
        clearGlobalData,
        getUserIntercomHashAndInitialize,
        allData,
		setAllData,
        getData
      }}
    >
      {children}
    </GlobalDataContext.Provider>
};