import React, { useEffect, useState, useRef } from 'react'
import { Icon } from 'semantic-ui-react'

import { PageHeaderContainer, HoverWrapper, RefreshIconWrapper } from '../../styled/styled'
import LoanPaymentsInfoTable from '../../components/InfoTables/LoanPaymentsInfoTable'
import ConstructionDrawsInfoTable from '../../components/InfoTables/ConstructionDrawsInfoTable'

/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function ConstructionDrawsPage() {
  return(
    <React.Fragment>
      <ConstructionDrawsInfoTable />
    </React.Fragment>
  )
}

export default ConstructionDrawsPage