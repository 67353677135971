import React, { useState, useContext, useEffect } from "react";
import { useAuth0 } from '../../react-auth0-spa';

export const GlobalUIContext = React.createContext();
export const useGlobalUI = () => useContext(GlobalUIContext);

export const GlobalUIProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  const [isMobile, setIsMobile] = useState()
  const [visible, setVisible] = useState()
  const [currentTab, setCurrentTab] = useState()

  useEffect(() => {
    setInitialActiveState(window.location.pathname)
  }, [])

  window.addEventListener('resize', (ev) => {
    if(ev.target.innerWidth < 1000) {
      setVisible(false)
      setIsMobile(true)
    } else {
      setIsMobile(false)
      if (isAuthenticated) setVisible(true)
    }
  })

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setVisible(false)
      setIsMobile(true)
    } else {
      setIsMobile(false)
      if (isAuthenticated) setVisible(true)
    }

    if (isAuthenticated && !isMobile) {
      setVisible(true)
    }
  }, [isAuthenticated])
  
  // sets current tab in the beginning, set different cases here
  function setInitialActiveState(currentLocation) {
    switch(currentLocation) {
      case '/rewards/':
        return setCurrentTab('rewards')
      case '/payoffs/':
        return setCurrentTab('payoffs')
      case '/payoff-request/':
        return setCurrentTab('payoffs')
      case '/construction-draws/':
        return setCurrentTab('construction draws')
      case '/loan-payments/':
        return setCurrentTab('loan payments')
      case '/loan-applications/':
        return setCurrentTab('loan applications')
      case '/user-settings/':
        return setCurrentTab('additional routes')
	  case '/help/':
		return setCurrentTab('help')
      default:
        return setCurrentTab('loans')
    }
  }

  const toggleVisibility = () => {
    console.log('before toggle: ', visible)
    setVisible(!visible)
    console.log('after toggle: ', visible)
  }

  // works with semantic ui components using name as input val
  const handleCurrentTabSemantic = (e, { name }) => setCurrentTab(name)

  const setCurrentTabManual = (val) => setCurrentTab(val)

  return (
    <GlobalUIContext.Provider
      value={{
        isMobile, 
        visible, 
        toggleVisibility,
        handleCurrentTabSemantic,
        setCurrentTabManual,
        currentTab
      }}
    >
      {children}
    </GlobalUIContext.Provider>
  );
};