import React from 'react'

const AccessDenialPage = () => 	{
	const imgurl = "/wcplogo_small.png"
	return <div className="wcp_container">
				<div className="wcp_logo">
					<img src={imgurl} /> 
				</div>	
				<div className="wcp_content">
					<div>
						<p className="salute">Dear Valued Borrower,</p>
						<p>We regret to inform you that you currently need to contact our servicing department to address an important matter related to your loan. In order to provide you with the best assistance and guidance, please reach out to our dedicated team at your earliest convenience.
						</p>
						<p>Our servicing department is here to help address any concerns or questions you may have about your loan.</p>
						<p style={{marginTop: '0'}}>They can be reached via phone at (703) 722-0774 or email at <a href='mailto:servicing@wcp.team'>servicing@wcp.team</a></p>
						<p className="salute">Thank you for your cooperation, and we look forward to assisting you further.</p>
						<p>Sincerely,</p>
						<p>Washington Capital Partners</p>
					</div>
				</div>
			</div>
}

export default AccessDenialPage