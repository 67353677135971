import React, { useEffect, useState, useRef } from 'react'
import { Icon } from 'semantic-ui-react'

import { PageHeaderContainer, HoverWrapper, RefreshIconWrapper } from '../../styled/styled'
import LoanPaymentsInfoTable from '../../components/InfoTables/LoanPaymentsInfoTable'
import {useGlobalData} from '../../contexts/GlobalData'
/**
 * 
 * @param {function} getData - gets screen data (app data), takes a setter function as parameter to set data on page 
 */
function LoanPaymentsPage() {
  return(
    <React.Fragment>
      <LoanPaymentsInfoTable />
    </React.Fragment>
  )
}

export default LoanPaymentsPage