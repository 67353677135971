import React, {useState, useEffect} from 'react'
import DrawsInnerInfoTable from './DrawsInnerInfoTable'
import {Grid} from 'semantic-ui-react'
const LoanDraws = ({loan, data, setFullData}) => {
	const [showDetails, setShowDetails] = useState(false)
	const [eligible, setEligible] = useState(true)
	
	useEffect(() => {
		let activePayoff = loan.Payoffs == null ? null : loan.Payoffs.filter(pf => pf.pfState == 'Active' && !['Expired', 'Canceled'].includes(pf.pfStatus))
		let activeDraw = loan.ConstructionDraws == null ? null : loan.ConstructionDraws.filter(d => d.state == 'Active' && !['Paid', 'Canceled'].includes(d.statusCodeText))
		setEligible((activePayoff == null || activePayoff.length ==0) && (activeDraw == null || activeDraw.length == 0))
	}, [])
	
	const btn_grid = {
		paddingLeft: '25px',
		cursor: 'pointer',
		borderRadius: '10px',
		border: '1px solid black'
	}
	return <div>
			<Grid style={btn_grid} onClick={()=> setShowDetails(!showDetails) }>
				<Grid.Row>
					{loan.propertyAddress}
				</Grid.Row>
			</Grid>
			{showDetails && <DrawsInnerInfoTable 
					sortedDrawsArray={loan.ConstructionDraws} 
					title='Construction Draws' 
					eligible={eligible}
					balance={loan.balance}
					disbursed={loan.disbursed}
					id = {loan.id}
					borrowerId = {loan.borrowerId}
					borrower ={loan.borrowerName}
					borrowerEntityId = {loan.borrowerEntityId}
					dataSource={data}
					setDataSource={setFullData}
				/>}
		</div>
}

export default LoanDraws