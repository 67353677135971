import React from 'react'
import { Icon, Label, Menu, Segment, Button, Tab, Header } from 'semantic-ui-react'
import { 
  PageHeaderContainer, 
  LoanPaymentTab as LoanPaymentTabStyled, 
  InfoTableContainer as LoanPaymentTabsContainer,
  COMPANY_EXTRA_DARK_BLUE,
  COMPANY_PRIMARY_COLOR,
  TabbedTableContainer,
  LOADER_SEGMENT_HEIGHT
} from '../../../styled/styled'
import LoanPaymentTab from './LoanPaymentTab';
import { useGlobalData } from '../../../contexts/GlobalData';

/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function LoanPaymentsInfoTable() {
  const { loansInfoMap, loans, borrowerInfo} = useGlobalData();
  //799520400 defaulted
  const has_defaulted = loans.some(l => l.servicingStatus == 799520400)
  
  const panes = loansInfoMap ? [
    { menuItem: (
        <Menu.Item style={{ borderLeft: 'none' }} key='Paid'>
          Paid <Label color='green' style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}>{loansInfoMap.totalPaidPaymentsCount || 0}</Label>
        </Menu.Item>
      ), render: () => (
        <div style={{ padding: 10 }}>
          <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Paid: {loansInfoMap.totalPaidPaymentsCount || 0}</PageHeaderContainer>
          <React.Fragment>
            {loansInfoMap.totalPaidPaymentsCount > 0 ?
            loans.map((loan, i) => {
            // hide loans that are not serviced by us, only show loans that have paid payments
            if(loan.LoanPayments.length > 0 && loan.loanServicer === "WCP Servicing" && loansInfoMap[loan.id].hasPaidPayments) {
                return (
                  <LoanPaymentTab tabData={loan} key={i} />
                )
              }
            })
            :
            <Segment placeholder>
              <Header icon>
                <Icon name='search' />
                No Loan Payments found.
              </Header>
            </Segment>}
          </React.Fragment>
        </div>
      )},
      { menuItem: (
        <Menu.Item key='Late'>
          Late <Label color='red' style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}>{loansInfoMap.totalLatePaymentsCount || 0}</Label>
        </Menu.Item>
      ), render: () => (
      <div style={{ padding: 10 }}>
        <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Late: {loansInfoMap.totalLatePaymentsCount || 0}</PageHeaderContainer>
        <React.Fragment>
          {loansInfoMap.totalLatePaymentsCount > 0 ?
          loans.map((loan, i) => {
            // hide loans that are not serviced by us, only show loans that have late payments
            if(loan.LoanPayments.length > 0 && loan.loanServicer === "WCP Servicing" && loansInfoMap[loan.id].hasLatePayments) {
              return (
                <LoanPaymentTab tabData={loan} key={i} />
              )
            }
          })
          :
          <Segment placeholder>
            <Header icon>
              <Icon name='search' />
              No Loan Payments found.
            </Header>
          </Segment>}
        </React.Fragment>
      </div>
    )},
    { menuItem: (
      <Menu.Item key='Unscheduled'>
        Upcoming <Label color='yellow' style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}>{loansInfoMap.totalUnscheduledPaymentsCount || 0}</Label>
      </Menu.Item>
    ), render: () => (
      <div style={{ padding: 10 }}>
        <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Upcoming: {loansInfoMap.totalUnscheduledPaymentsCount || 0}</PageHeaderContainer>
        <React.Fragment>
          {loansInfoMap.totalUnscheduledPaymentsCount > 0 ?
          loans.map((loan, i) => {
          // hide loans that are not serviced by us, only show loans that have unscheduled payments
          if(loan.LoanPayments.length > 0 && loan.loanServicer === "WCP Servicing" && loansInfoMap[loan.id].hasUnscheduledPayments) {
              return (
                <LoanPaymentTab tabData={loan} key={i} />
              )
            }
          })
          :
          <Segment placeholder>
            <Header icon>
              <Icon name='search' />
              No Loan Payments found.
            </Header>
          </Segment>}
        </React.Fragment>
      </div>
    )}
  ] : []

  return (
    <TabbedTableContainer>
      {loansInfoMap ?
      <Tab panes={panes} /> 
      :
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, margin: 'auto' }} />}
    </TabbedTableContainer>
  )
}

export default LoanPaymentsInfoTable