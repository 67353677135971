import React, { useState, useEffect } from 'react'
import { Menu, Label, Segment, Tab, Header, Icon } from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';

import { 
  InfoTable,
  PageHeaderContainer,
  COMPANY_PRIMARY_COLOR,
  LOADER_SEGMENT_HEIGHT,
  HEADER_3_SIZE,
  HoverTableRow,
  COMPANY_EXTRA_DARK_BLUE,
  TabbedTableContainer
} from '../../styled/styled'
import { GetLoanStatusText } from '../../lib/helpers'
import { withRouter } from 'react-router-dom';
import { useGlobalData } from '../../contexts/GlobalData';

/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function LoansInfoTable({ history }) {
  const { loansInfoMap, loans, loansMap, hasFishLoans } = useGlobalData()
  const [groupedLoans, setGroupedLoans] = useState(null)

  useEffect(() => {
    if (loans && !groupedLoans) {

      //let group = loans.filter(l=>l.statusText != 'Processing_Underwriting').reduce((r, a) => {
	  let group = loans.reduce((r, a) => {
        r[a.statusCode] = [...r[a.statusCode] || [], a];
        return r;
      }, {});

      // flatten grouped data object
      setGroupedLoans(Object.keys(group).map((k, i) => group[k]).flat())

      console.log('From Loans Info Table: ', loansInfoMap)
    }
  }, [loans, loansInfoMap])

  // active loans table
  function renderActiveLoans() {
    return (
      <div style={{ padding: 10 }}>
        <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Active Loans: {loansInfoMap.totalActiveLoansCount}</PageHeaderContainer>
        <InfoTable>
          <thead>
            <tr>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Name</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Property Address</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Amount ($)</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Closing Date</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Status</p></th>
            </tr>
          </thead>
          <tbody>
          {loans && groupedLoans &&
            groupedLoans.map((loan, i) => {
              // if the loans are in processing or servicing statuses
              if (GetLoanStatusText(loan.statusCode).toLowerCase().includes('processing') || GetLoanStatusText(loan.statusCode).toLowerCase().includes('servicing')) {
                return (
                  <HoverTableRow onClick={() => history.push('/loan-details/' + loan.id)} key={i}>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loan.name}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loan.propertyAddress}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>${numeral(loan.amount).format('0,0.00')}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{moment.utc(loan.closingDate).format('MM/DD/YYYY')}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{GetLoanStatusText(loan.statusCode)}</p></td>
                  </HoverTableRow>
                )
              }
            })}
          </tbody>
        </InfoTable>
      </div>
    )
  }


  // paid off loans table
  function renderPaidOffLoans() {
    return (
      <div style={{ padding: 10 }}>
        <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Active Loans: {loansInfoMap.totalActiveLoansCount}</PageHeaderContainer>
        <InfoTable>
          <thead>
            <tr>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Name</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Property Address</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Amount ($)</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Closing Date</p></th>
              <th><p style={{ fontSize: HEADER_3_SIZE }}>Status</p></th>
            </tr>
          </thead>
          <tbody>
          {loans && groupedLoans &&
            groupedLoans.map((loan, i) => {
              // paid off loans only
              if (GetLoanStatusText(loan.statusCode) === 'Paid Off') {
                return (
                  <HoverTableRow onClick={() => history.push('/loan-details/' + loan.id)} key={i}>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loan.name}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loan.propertyAddress}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>${numeral(loan.amount).format('0,0.00')}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{moment.utc(loan.closingDate).format('MM/DD/YYYY')}</p></td>
                    <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{GetLoanStatusText(loan.statusCode)}</p></td>
                  </HoverTableRow>
                )
              }
            })}
          </tbody>
        </InfoTable>
      </div>
    )
  }

  // show loader if no page data, showing table looks bad
  if (!loans) {
    return (
      <Segment loading style={{ height: LOADER_SEGMENT_HEIGHT, width: '60%', margin: 'auto' }} />
    )
  }

  // each tab is rendered here, decide what to render based on tab data
  const panes = loansInfoMap ? [
    { menuItem: (
        <Menu.Item style={{ borderLeft: 'none' }} key='Active'>
          Active <Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}>{loansInfoMap.totalActiveLoansCount || 0}</Label>
        </Menu.Item>
    ), 
    render: () => 
      loansInfoMap.totalActiveLoansCount > 0 ? 
      renderActiveLoans() 
      : 
      (<Segment placeholder>
        <Header icon>
          <Icon name='search' />
          No Loans found.
        </Header>
      </Segment>)
    },
    { menuItem: (
        <Menu.Item key='Paid Off'>
          Paid Off <Label style={{ backgroundColor: COMPANY_EXTRA_DARK_BLUE }}>{loansInfoMap.totalPaidOffLoansCount || 0}</Label>
        </Menu.Item>
    ),
    render: () =>
      loansInfoMap.totalPaidOffLoansCount > 0 ?
      renderPaidOffLoans()
      :
      (<Segment placeholder>
        <Header icon>
          <Icon name='search' />
          No Loans found.
        </Header>
      </Segment>)
    }
  ] : []

  return (
		
		<TabbedTableContainer>
		{hasFishLoans ? <div style={{
					marginBottom: '10px', 
					width: '100%', 
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					background: '#2185D0',
					color: 'white',
					padding: '15px',
					textAlign: 'center'
					}} 
		><h4>We are Launching a New & Improved WCP Borrower Experience. Follow this <a style={{color: '#00ffff', textDecoration: 'underline'}} href='https://ifish.tech/version-live/borrower-portal'>Link</a> to the Updated Borrower Portal where you will manage your new Loan Apps and Loans</h4></div> : <div></div>}
			<Tab panes={panes} />
		</TabbedTableContainer>
  )
}

export default withRouter(LoansInfoTable)