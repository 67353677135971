import React, { useState, useEffect } from 'react'
import { Header, Icon, Segment, Button } from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';
import PayoffRequestForm from '../Forms/PayoffRequestForm'

import { 
  InfoTable,
  InfoTableContainer, 
  PageHeaderContainer,
  COMPANY_PRIMARY_COLOR,
  HEADER_3_SIZE,
} from '../../styled/styled'
import { GetLoanStatusText, sortDatesDesc } from '../../lib/helpers'
import { withRouter } from 'react-router-dom';
import { useGlobalData } from '../../contexts/GlobalData';

/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function LoanApplicationsInfoTable({ history }) {
  const { loansInfoMap, loanApplications } = useGlobalData()

  return (
    <InfoTableContainer style={{ padding: 10 }}>
      <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Loan Applications</PageHeaderContainer>
      {loansInfoMap && loanApplications && loanApplications.length > 0 ?
      <InfoTable>
        <thead>
          <tr>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Requested Closing Date</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Requested Loan Amount</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Property Address</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Borrower Entity</p></th>
          </tr>
        </thead>
        <tbody>
        {loanApplications.sort((la1, la2) => sortDatesDesc(la1.requestedClosingDate, la2.requestedClosingDate)).map((loanApp, i) => {
          return (
            <tr key={i}>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{moment.utc(loanApp.requestedClosingDate).format('MM/DD/YYYY')}</p></td>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>${numeral(loanApp.amount).format('0,0.00')}</p></td>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loanApp.propertyAddress}</p></td>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{loanApp.borrowerEntity}</p></td>
            </tr>
          )
        })}
        </tbody>
      </InfoTable>
      :
      <Segment placeholder>
        <Header icon>
          <Icon name='search' />
          No Loan Applications found.
        </Header>
      </Segment>}
    </InfoTableContainer>
  )
}

export default withRouter(LoanApplicationsInfoTable)