import React, { useState, useEffect } from 'react'
import { Header, Icon, Segment, Button } from 'semantic-ui-react'
import moment from 'moment';
import numeral from 'numeral';
import PayoffRequestForm from '../Forms/PayoffRequestForm'

import { 
  InfoTable, 
  HoverWrapper, 
  FlexThis, 
  InfoTableContainer, 
  PageHeaderContainer,
  COMPANY_PRIMARY_COLOR,
  LOADER_SEGMENT_HEIGHT,
  HEADER_3_SIZE,
  HoverTableRow,
  UPCOMING_PASTEL_YELLOW,
  POSITIVE_PASTEL_GREEN,
  COMPANY_EXTRA_DARK_BLUE,
  TabbedTableContainer,
  InnerTableContainer,
  COMPANY_ACTIVE_BLUE
} from '../../styled/styled'
import { GetLoanStatusText, sortDatesDesc } from '../../lib/helpers'
import { withRouter } from 'react-router-dom';
import { useGlobalData } from '../../contexts/GlobalData';

/**
 * 
 * @param {object} data - The Loans array, required. 
 * @param {string} type - Type of info table to render. Will default to active loans.
 */
function PayoffsInfoTable({ history }) {
  const { loansInfoMap, borrowerInfo, loansMap, loans } = useGlobalData()
  
  return (
    <InfoTableContainer>
      <PageHeaderContainer style={{ color: COMPANY_PRIMARY_COLOR }}>Payoffs</PageHeaderContainer>
      <hr />
      <FlexThis>
        <Button 
        disabled={(loans && !(loans.length > 0))}
        style={{ 
          width: 'fit-content', 
          marginLeft: 'auto', 
          backgroundColor: COMPANY_ACTIVE_BLUE,
          color: 'white'
        }} 
        onClick={() => history.push('/payoff-request/')}>Request Payoff</Button>
      </FlexThis>
      <hr />
      {loansInfoMap.payoffsArray.length > 0 ?
      <InfoTable>
        <thead>
          <tr>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Name</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Property Address</p></th>
            <th><p style={{ fontSize: HEADER_3_SIZE }}>Payoff Date</p></th>
			<th><p style={{ fontSize: HEADER_3_SIZE }}>Payoff Status</p></th>
          </tr>
        </thead>
        <tbody>
        {loansInfoMap.payoffsArray.sort((p1, p2) => sortDatesDesc(p1.payoffDate, p2.payoffDate)).map((payoff, i) => {
          return (
            <tr key={i}>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{payoff.payoffName}</p></td>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{payoff.propertyAddress}</p></td>
              <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{moment.utc(payoff.payoffDate).format('MM/DD/YYYY')}</p></td>
			   <td><p style={{ fontSize: HEADER_3_SIZE, paddingTop: 5, paddingBottom: 5 }}>{payoff.pfStatus}</p></td>
            </tr>
          )
        })}
        </tbody>
      </InfoTable>
      :
      <Segment placeholder>
        <Header icon style={{ display: 'block', margin: 'auto' }}>
          <Icon name='search' />
          No Payoffs found.
        </Header>
      </Segment>}
    </InfoTableContainer>
  )
}

export default withRouter(PayoffsInfoTable)